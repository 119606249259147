@import 'assets/css/variables.scss';
.form-verify-forgot {
  background: #ffffff;
  border-radius: 4px;
  max-width: 674px;
  width: 100%;
  .verify-code {
    min-height: 360px;
    max-width: 580px;
    .back-link {
      margin-bottom: 0;
    }
    .form-item-input label span {
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: #003e9d;
      margin-bottom: 0;
      @include media-up(sm) {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
      }
    }
    .form-verify-forgot-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
      @include media-up(md) {
        margin-bottom: 48px;
      }
      p {
        font-weight: 700;
        font-size: 32px;
        line-height: 43px;
        color: #003e9d;
      }
    }
    @include media-up(md) {
      padding: 60px;
    }
    @include media-up(lg) {
      padding: 160px 80px;
      max-height: 600px;
    }
    p {
    }
    .verify-title {
      color: #ffffff;
      font-weight: 400;
      font-size: 24px;
      line-height: 44px;
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 30px;

      @include media-up(sm) {
        font-size: 32px;
      }
    }
    .verify-resend {
      display: flex;
      justify-content: space-between;
      margin-top: 14px;
      .time-count {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #003e9d;
      }
      > a {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #003e9d;
        cursor: pointer;

        @include media-up(sm) {
          font-size: 16px;
        }
      }
      a:hover {
        opacity: 0.7;
      }
      a:active {
        opacity: 1;
      }
    }
    .verify-error {
      color: $warning;
      font-size: 13px;
      text-align: center;
      padding: 10px 10px;
      @include media-up(sm) {
        font-size: 16px;
      }
    }
    .verify-action-forgot-password {
      width: 100%;
      text-align: center;
      margin-top: 32px;
      // margin-bottom: 20px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      row-gap: 20px;

      button {
        width: 110px;
        @include media-up(sm) {
          width: 150px;
        }
      }
    }
    .verify-input {
      margin-top: 14px;
      p {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;

        @media (min-width: 576px) {
          font-size: 18px;
        }
      }
    }
  }
}
