@import 'assets/css/variables.scss';

.footer-auth {
  width: 100%;
  min-height: 70px;

  display: none;
  @media screen and (min-width: 991px) {
    display: flex;
    position: absolute;
    top: 0;
    left: -20px;
    right: 0;
  }

  ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    z-index: 5;

    width: 100%;
    list-style: none;
    margin: 0;

    li {
      padding: 40px 6px;
      @include media-up(xl) {
        padding: 40px 20px;
      }
      a {
        color: #0f1b36;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        @include media-up(lg) {
          font-size: 16px;
        }
        &:hover {
          color: #0f1b36;
        }
      }
    }
  }
}
