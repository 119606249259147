@import 'assets/css/variables.scss';

.modal-verification-code {
  margin: 0 auto;
  padding-bottom: 0;
  .modal-title-wd {
    // font-weight: 400;
    // font-size: 24px;
    // line-height: 100%;

    text-align: center;
    text-transform: capitalize;
    color: #292929;

    font-weight: 700;
    font-size: 34px;
    line-height: 140%;
    @media (min-width: 991px) {
      font-size: 32px;
    }
  }
  .ant-modal-content {
    padding: 75px 24px 125px 24px;
    border-radius: 12px;
    @media (min-width: 991px) {
      padding: 60px 70px;
    }
    .ant-modal-close {
      top: 35px;
      right: 25px;

      .anticon {
        svg {
          width: 30px;
          height: 30px;
          @include media-down(sm) {
            width: 20px;
            height: 20px;
          }
          path {
            fill: #ff4343;
          }
        }
      }
    }
    p {
      text-align: left;
    }

    .ant-modal-header {
      margin-bottom: 40px;
      padding: 0;
    }

    .ant-modal-body {
      padding: 0;

      .title-modal {
        margin-bottom: 32px;
      }

      .list-steps-verify {
        margin-bottom: 36px;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: flex;
          align-items: center;
          justify-content: center;

          li {
            position: relative;
            padding-left: 30px;

            &.current {
              &::before {
                content: '';
                background: #007ca2;
                border: 1px solid #ffffff;
              }
            }

            &.active {
              &::before {
                content: '✔';
                color: #0c1d76;
                background: #007ca2;
                border: 1px solid #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              height: 18px;
              width: 18px;
              background: #007ca2;
              border: 1px solid #ffffff;
              box-sizing: border-box;
              border-radius: 50%;
              z-index: 1;
            }

            &:last-child {
              padding-bottom: 0;
              margin-left: 30px;
              &::after {
                content: unset;
              }
            }

            p {
              //font-weight: 600;
              font-size: 16px;
              line-height: 18px;
              //color: #ffffff;
              margin-bottom: 0;
              font-weight: 700;
              //font-size: 14px;
              //line-height: 140%;
              color: #292929;
            }
          }
        }

        @media (min-width: 991px) {
          ul {
            li {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }

      .modal-verification-code-note {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        //color: #a5adcf;
        margin-bottom: 16px;
        font-size: 14px;
        color: #007ca2;

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .input-code-container > div .input-code-item input {
        background: #fbfbfb;
        border-radius: 4px;
        border: 1px solid #08a7b7;
        color: #292929;
      }
      .input-code-container {
        margin-bottom: 10px;
        .input-code-item {
          height: 60px;
        }
      }
      .resend-code p {
        font-weight: 400;

        color: #007ca2;
      }
      .button-primary {
        background: transparent;
        border-radius: 8px;
        color: #292929;
        border: 1px solid #069cc9;
        &:last-child {
          background: #069cc9;
          border-radius: 8px;
          color: #ffffff;
        }
      }

      @media (min-width: 991px) {
        .modal-verification-code-note {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 11px;
        }

        .input-code-container {
          margin-bottom: 16px;
          .input-code-item {
            height: 80px;
          }
        }
      }
    }

    .modal-scan-action {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
  .ant-modal {
    max-width: 100vw;
    bottom: 0;
    top: unset;
    position: fixed;
    @media (min-width: 576px) {
      max-width: unset;
      position: unset;
    }
  }
}
