@import 'assets/css/variables.scss';

.modal-remove-address {
  .remove-address-title {
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 32px;
    text-align: center;

    color: #ff4343;
    @include media-down(sm) {
      font-size: 16px;
    }
  }
  p {
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;
    text-align: center;

    color: #0e0e0e;
    @include media-down(sm) {
      font-size: 14px;
    }
  }
  .ant-modal-header {
    padding: 48px 32px 0;
    @include media-down(sm) {
      padding: 24px 18px 0;
    }
  }
  .ant-modal-close {
    top: 60px;
    @include media-down(sm) {
      top: 35px;
    }
  }
  .ant-modal-body {
    padding: 48px 32px;
    @include media-down(sm) {
      padding: 24px 18px;
    }
    .generate-confirm {
      margin-top: 48px;
      @include media-down(sm) {
        margin-top: 24px;
      }
    }
  }
}
.ant-modal.custom-modal.modal-remove-address {
  width: 681px !important;
}
