@import 'assets/css/variables.scss';

.contact-container {
  width: 100%;
  margin-bottom: 140px;

  .contact-header {
    .sub-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 2rem;
      color: #003e9d;
      margin-top: 32px;
    }

    @include media-up(md) {
      font-size: 16px;
    }
    .back-link {
      .back-title {
        color: #003e9d;
        font-weight: 700;
        font-size: 24px;
        @include media-up(md) {
          font-size: 32px;
        }
      }
    }
  }

  .contact-body {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-width-pen {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 27px;
        color: #ffffff;
        margin-bottom: 0;
      }

      .box-input {
        position: relative;
        max-width: 100%;
        p {
          font-size: 18px;
        }
        .ant-form-item {
          @media (min-width: 991px) {
            margin-bottom: 32px;
          }

          .ant-form-item-label {
            span {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 16px;

              @include media-up(md) {
                font-size: 20px;
              }
            }
          }

          .ant-form-item-explain-error {
            padding: 4px 0 0;
          }
        }
        input,
        textarea {
          border: 1px solid #34384c;
          box-sizing: border-box;
          border-radius: 10px;
          height: 50px;
          width: 100%;
          padding-left: 12px;
          padding-right: 40px;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #000101;
          background-color: transparent;
        }

        textarea {
          height: auto;
        }

        img {
          position: absolute;
          right: 15px;
          top: 50px;
          // top: 50%;
          // transform: translateY(-50%);
          width: 16px;
          cursor: pointer;
        }

        .ant-select-selector {
          height: 50px;
          width: 100%;
          color: #003e9d;
          font-weight: 700;
          font-size: 14px;

          background-color: transparent;
          border: 1px solid #34384c;
          border-radius: 10px;
          border-color: #34384c !important;
          padding-left: 12px;

          .ant-select-selection-item {
            line-height: 50px;
          }

          input {
            border: unset;
            padding: 0;
          }
        }

        .ant-picker {
          background-color: transparent;
          color: #003e9d;
          border: 1px solid #34384c;

          .ant-picker-suffix,
          .ant-picker-clear {
            opacity: 0;
          }

          input {
            background-color: transparent;
            border: none;
          }
        }
      }
    }

    .input-file-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 48px;
      }

      .top-sub {
        display: none;
      }

      .input-file-item-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
        margin-bottom: 8px;
      }

      .input-file-item-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #a5adcf;
        margin-bottom: 32px;
      }

      .ant-upload.ant-upload-drag {
        background-color: transparent;
        border: none;

        img {
          max-height: 280px;
        }
      }

      .ant-upload.ant-upload-btn {
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 32px 40px;
      }

      .ant-upload-drag-icon {
        margin-bottom: 32px;

        img {
          max-width: 100%;
        }
      }

      .ant-upload-text {
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        font-feature-settings: 'cv11' on, 'cv02' on, 'cv03' on, 'cv04' on;
        color: $text-main;

        width: 100%;
        max-width: 250px;
        padding: 8px 16px;
        margin: 0 auto;
        background: #1a3035;
        border-radius: 10px;

        @include media-up(md) {
          font-size: 18px;
        }
      }

      .box-input {
        max-width: 100%;
        justify-content: center;

        .image-example {
          display: none;
        }
      }
    }
  }
  .submit-btn {
    width: 110px;
    @include media-up(md) {
      width: 150px;
    }
  }
}
