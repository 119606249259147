@import 'assets/css/variables.scss';

.warp-realtime {
  // margin-top: -60px;
  position: relative;
  max-width: 1070px;
  margin: 280px auto 0px;
  // overflow: hidden;
  z-index: 10;
  @include media-down(xl) {
    //  max-width: 100% !important;
    margin: 200px 24px 0px;
  }
  @include media-down(md) {
    margin-bottom: 200px;
    margin: 0 24px;
  }
  // @include media-between(sm, md) {
  //   max-width: 70%;
  //   margin: 0px auto 200px;
  // }
  &_first {
    margin-right: 150px;
    margin-bottom: 80px;
    @include media-down(xl) {
      margin: 0 auto 80px;
    }

    .group-realTime {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      @include media-down(lg) {
        margin-bottom: 48px;
        flex-direction: column;
        align-items: unset;
        gap: 16px;
      }

      &_col {
        .icon-bot {
          margin-top: 32px;

          display: flex;
          justify-content: space-around;
          align-items: center;
          li {
            color: #000000;
            font-size: 14px;
            font-weight: 500;
          }
          img {
            width: 32px;
          }
        }
      }
      //process
      .process {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        // margin-left: 30px;
        @include media-down(lg) {
          justify-content: center;
        }
        @include media-down(md) {
          margin: 16px 24px;
        }
        @include media-down(400px) {
          margin: 16px 8px;
        }
        &_bg {
          @include media-down(sm) {
            text-align: center;
            //width: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          img {
            width: 54px;
            height: 70px;
            @include media-down(sm) {
              width: 35px;
              height: 45px;
            }
          }
          .bg_icons {
            width: 110px;
            height: 110px;
            display: flex;
            justify-content: center;
            background-color: #ffffff;
            padding: 20px 18px;
            margin-bottom: 8px;
            border-radius: 12px;
            @include media-down(sm) {
              // padding: 12px 11px;
              width: 70px;
              height: 70px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          h3 {
            font-size: 16px;
            font-weight: 600;
            @include media-down(sm) {
              font-size: 14px;
              white-space: nowrap;
            }
          }
        }

        .arrow {
          margin: 0px 40px;
          // max-width: 180px;
          max-width: 280px;
          @include media-down(md) {
            margin: 0px 16px;
          }
          @include media-down(sm) {
            position: relative;
            top: -6px;
          }
          @include media-down(xm) {
            margin: 0px 8px;
          }

          p {
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 19.6px;
            color: #000000;
            @include media-down(sm) {
              font-size: 10px;
            }
            @include media-down(xm) {
              font-size: 8px;
            }
          }
          img {
            width: 100%;
            margin-top: -20px;
          }
          &_left {
            margin-top: 20px;
            @include media-down(md) {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }

  // second
  &_second {
    margin-left: 190px;
    margin-bottom: 32px;
    @include media-down(xl) {
      margin: 0 auto;
    }
    .reverse {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      @include media-down(lg) {
        flex-direction: column-reverse;
        align-items: unset;
        gap: 16px;
      }
      // @include media-down(770px) {
      //   flex-direction: column-reverse;
      //   align-items: unset;
      //   gap: 16px;
      // }

      &_col {
        .icon-bot {
          margin-top: 32px;

          display: flex;
          justify-content: space-around;
          align-items: center;
          li {
            color: #000000;
            font-size: 14px;
            font-weight: 500;
          }
          img {
            width: 32px;
          }
        }
      }
      //process
      .process_right {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        // margin-right: 30px;
        @include media-down(lg) {
          justify-content: center;
        }
        @include media-down(md) {
          padding: 16px 26px 0px;
        }
        @include media-down(400px) {
          padding: 16px 8px;
        }
        // @include media-down(330px) {
        //   padding: 16px 7px;
        // }
        // @include media-down(md) {
        //   margin: 16px 26px 0px;
        // }
        .bg_data {
          .textData {
            margin-bottom: 14px;
            //width: 110px;
            text-align: center;
            background-color: #ffffff;
            padding: 12px 8px;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include media-down(md) {
              //width: 90px;
            }
            @include media-down(sm) {
              margin-bottom: 18px;
              border-radius: 4px;
            }
          }

          p {
            font-size: 14px;
            line-height: 140%;
            color: #003e9d;
            white-space: nowrap;
            @include media-down(sm) {
              font-size: 10px;
            }
          }
        }
        h3 {
          font-size: 16px;
          font-weight: 600;
          @include media-down(sm) {
            font-size: 14px;
          }
        }

        .bg_img {
          display: flex;
          flex-direction: column;
          align-items: center;
          @include media-down(sm) {
            text-align: center;
          }
          img {
            width: 54px;
            height: 70px;
            @include media-down(sm) {
              width: 35px;
              height: 45px;
            }
          }
          .bg_icons {
            width: 110px;
            height: 110px;
            background: #ffffff;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
            @include media-down(sm) {
              width: 70px;
              height: 70px;
              background: #ffffff;
              border-radius: 12px;
            }
          }
        }
        .arrow {
          // margin: 0px 40px 40px;
          margin: 0px 40px;
          // max-width: 140px;
          max-width: 220px;

          @include media-down(md) {
            margin: 0px 18px;
          }
          @include media-down(sm) {
            margin: 0px 12px;
            position: relative;
            top: -5px;
          }

          p {
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 19.6px;
            color: #000000;
            @include media-down(sm) {
              font-size: 10px;
            }
            @include media-down(xm) {
              font-size: 8px;
            }
          }
          img {
            width: 100%;
            margin-top: -20px;
          }
          // &_right {
          //   @include media-down(sm) {
          //     margin-bottom: 12px;
          //   }
          // }
          &_left {
            margin-top: 20px;
            @include media-down(md) {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }

  //btn
  &_btn {
    text-align: center;
    position: relative;
    z-index: 5;
    .button-primary {
      width: 150px;
      @include media-down(sm) {
        width: 110px;
      }
    }
  }
  &:after {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    /* opacity: 0.3; */
    background-image: url('../../../../assets/images/bg-evm2.png');
    max-width: 1440px;
    height: 495px;
    margin: -10rem 0rem;
    // opacity: 0.3;
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
