@import 'assets/css/variables.scss';

.wrapper-form-forgot {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  //margin-bottom: 24px;
  // @include media-between(xs,xm) {
  //   padding: 0 16px;
  // }
  @media screen and (min-height: 400px) {
    align-items: center;
    padding-top: 8%;
  }
  .back-link {
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include media-up(sm) {
      justify-content: center;
    }
    @include media-up(md) {
      margin-bottom: 52px;
    }

    .back-title {
      width: 100%;
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #003e9d;

      @include media-up(sm) {
        //display: block;
      }
      @include media-up(md) {
        font-size: 32px;
        line-height: 43px;
      }
    }
    .back-icon {
      // margin-right: 50px;
      // @include media-up(sm) {
      //   margin-right: 10px;
      // }
      margin-right: 0;
      img {
        width: 13px;
        height: 18px;
        @include media-up(sm) {
          width: 14px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }
  }

  .forgot-label {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .form-forgot {
    max-width: 630px;
    padding: 24px;
    background: #ffffff;
    border-radius: 4px;
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px 80px;
      min-height: 674px;
    }
    .button-primary {
      width: 110px;
      @include media-up(md) {
        width: 150px;
      }
    }
    .form-action {
      width: 100%;
      max-width: 110px;
      margin: 16px auto;

      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      @include media-up(sm) {
        max-width: 150px;
        margin: 24px auto;
      }
    }

    .form-links {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      a {
        color: $link;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-left: 5px;

        @include media-up(sm) {
          font-size: 16px;
        }

        &:hover {
          opacity: 0.7;
        }
        &:active {
          opacity: 1;
        }
      }
    }
  }
}
