@import 'assets/css/variables.scss';

.language-setting {
  .ant-select-item .ant-select-item-option-content {
    padding: 0;
  }
}
.header-container-desktop {
  height: 80px;
  padding: 0;
  //background: $secondary;
  position: relative;
  display: none;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-select {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #003e9d;
  }
  @include media-up(lg) {
    display: block;
    height: 100px;
  }

  .header-logo {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    // margin-right: 20px;

    cursor: pointer;
    img {
      width: 100%;
      max-width: 264px;
      object-fit: contain;
      // margin-right: 10px;
    }
  }
  .header-desktop {
    // background: #f1faff;
    width: 100%;
    height: 100%;
    max-width: 1300px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .header-link {
      list-style: none;
      margin: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      li {
        white-space: nowrap;
        width: 100%;
        height: 100%;
        padding: 0 15px;
        display: block;
        @include media-up(xl) {
          padding: 0 26px;
        }
        a {
          color: #0f1b36;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      li.active {
        a {
          color: #0f1b36;
        }
      }
    }
    .header-right {
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-right: 0;
      }
      & .actions {
        margin-left: 15px;
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
          padding-right: 0;
        }
      }

      .avatar-image {
        width: fit-content;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        & > div {
          width: 40px;
          height: 40px;
          padding: 2px;
          margin-right: 10px;
          border-radius: 100%;
          background: linear-gradient(to left, rgba(0, 117, 254, 1) 0%, rgba(0, 194, 254, 1) 100%);
          overflow: hidden;
          display: block;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        & > span {
          color: #fcfcfd;
          font-weight: 600;
          font-size: 16px;
          line-height: 130%;
          white-space: nowrap;
        }
      }
    }
  }
}

.menu-download-dropdown {
  li:hover,
  li:focus-visible {
    background-color: transparent;
    border-color: transparent;
  }
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-right: 0;
}
