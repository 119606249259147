@import 'assets/css/variables.scss';

.warp-banner {
  z-index: 5;
  position: relative;
  max-width: 1290px;
  margin: 200px auto 0px;
  @include media-down(xl) {
    margin: 120px 24px;
  }
  .group_banner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include media-down(xl) {
      flex-direction: column;
      align-items: center;
    }
    @include media-down(md) {
      //max-width: 382px;
      margin: 0 auto;
    }
    .group-left {
      width: 550px;
      margin-right: 80px;
      height: 517px;
      @include media-down(xl) {
        margin-bottom: 16px;
      }
      @include media-down(md) {
        display: flex;
        // justify-content: space-between;
        margin-right: 0;
        align-items: center;
        margin-top: -120px;
        max-width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        @include media-down(md) {
          width: 50%;
          height: 196px;
          object-fit: contain;
        }
      }
      p {
        display: none;
        @include media-down(md) {
          display: block;
          font-size: 24px;
          font-weight: 700;
          color: #1b1b1b;
        }
      }
    }
    .group-right {
      z-index: 10;
      border-radius: 30px;
      width: 660px;
      height: 517px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include media-down(md) {
        margin-top: -17rem;
        width: 100%;

        border-radius: 17px;
      }
      @include media-down(sm) {
        height: 240px !important;
      }

      // @include

      .app-top {
        align-items: center;
        padding: 55px 46px 80px;
        display: flex;
        justify-content: space-between;
        // @include media-down(md) {
        //   padding: 38px 35px 47px;
        // }
        @include media-down(sm) {
          padding: 38px 35px 47px;
        }
        .ellipse {
          position: relative;
          width: 102px;
          height: 102px;
          display: flex;
          align-items: center;
          justify-content: center;

          img.ellipse-border {
            width: 102px;
            height: 102px;
            @include media-down(sm) {
              width: 59px;
              height: 59px;
            }
          }
          img.ellipse-img {
            width: 145px;
            height: 145px;
            @include media-down(sm) {
              width: 80px;
              height: 80px;
            }
          }

          .ellipse-img {
            position: absolute;
            top: -13px;
            right: -24px;
            @include media-down(sm) {
              top: -6px;
              left: -9.5px;
            }
          }
          @include media-down(sm) {
            width: 59px;
            height: 59px;
          }
        }
      }
      .app-bottom {
        align-items: center;
        padding: 0px 152px 56px;

        display: flex;
        justify-content: space-between;
        .ellipse {
          position: relative;
          width: 102px;
          height: 102px;
          display: flex;
          align-items: center;
          justify-content: center;

          img.ellipse-border {
            width: 102px;
            height: 102px;
            @include media-down(sm) {
              width: 59px;
              height: 59px;
            }
          }
          img.ellipse-img {
            width: 145px;
            height: 145px;
            @include media-down(sm) {
              width: 80px;
              height: 80px;
            }
          }

          .ellipse-img {
            position: absolute;
            top: -13px;
            right: -24px;
            @include media-down(sm) {
              top: -6px;
              left: -9.5px;
            }
          }
          @include media-down(sm) {
            width: 59px;
            height: 59px;
          }
        }
        // @include media-down(md) {
        //   padding: 0px 98px 43px;
        // }
        @include media-down(sm) {
          padding: 0px 87px 43px;
        }
        @include media-down(xm) {
          padding: 0px 67px 56px;
        }
        img {
          width: 83px;
          height: 83px;
          @include media-down(md) {
            width: 58px;
            height: 58px;
          }
        }
      }
      p {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        color: #f2faff;
        @include media-up(sx) {
          display: none;
        }
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/images/bg-evm1.png');
    /* opacity: 0.3; */
    width: 1440px;
    height: 495px;
    top: 38rem;

    left: -8rem;
    opacity: 0.3;
    // display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
