@import 'assets/css/variables.scss';

.button-logout {
  width: 110px;
  font-size: 72px;
  background: #be0000;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  // width: 100%;
  height: 48px;
  padding: 3px;
  //border: 2px solid#FF5657;
  border: none;
  border-radius: 10px;
  align-items: center;
  outline: unset;
  cursor: pointer;
  position: relative;
  // max-width: 90px;
  @include media-up(md) {
    max-width: 150px;
    width: 150px;
    height: 40px;
  }
  &[data-size='small'] {
    font-size: 10px;
    line-height: 20px;
    height: 20px;
    @include media-up(sm) {
      font-size: 12px;
      height: 26px;
    }
  }
  &[data-size='standard'] {
    font-size: 14px;
    line-height: 22px;
    height: 40px;
    @include media-up(sm) {
      font-size: 16px;
      height: 48px;
      max-width: 150px;
    }
  }
  &[data-size='larger'] {
    font-size: 16px;
    line-height: 24px;
    height: 56px;
    @include media-up(sm) {
      font-size: 18px;
      height: 66px;
    }
  }

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 1;
  }

  &[disabled],
  &[data-loading='true'] {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &[data-hoverable='false'] {
    opacity: 1;
    cursor: default;
  }

  .ant-spin {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
