@import 'assets/css/variables.scss';

.header-container-mobile {
  height: 80px;
  padding: 0;
  background: transparent;
  position: relative;
  display: block;
  z-index: 1;
  @include media-up(lg) {
    display: none;
    height: 100px;
  }

  .header-logo {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    img {
      width: 100%;
      max-width: 160px;
      object-fit: contain;
      margin-right: 20px;
    }
  }

  .header-mobile {
    width: 100%;
    height: 100%;
    padding: 0 24px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .header-logo {
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        max-width: 200px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
    .header-right {
      display: flex;
    }

    .collapse-icon {
      display: flex;
      align-items: center;
      transform: scale(-1);

      input#menu {
        display: none;
      }

      .icon {
        width: 34px;
        height: auto;
        padding: 0;
        display: block;
        cursor: pointer;
      }

      .icon .menu,
      .icon .menu::before,
      .icon .menu::after {
        background: #0093e5;
        content: '';
        display: block;
        height: 3px;
        position: absolute;
        transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
      }
      .icon .menu::before {
        width: 28px;
      }
      .icon .menu {
        width: 24px;
      }
      .icon .menu::after {
        width: 34px;
      }

      .icon:hover .menu,
      .icon:hover .menu::before,
      .icon:hover .menu::after {
        background: #fff;
      }

      .icon .menu::before {
        top: -8px;
      }

      .icon .menu::after {
        top: 8px;
      }

      #menu:checked + .icon .menu {
        background: transparent;
      }

      #menu:checked + .icon .menu::before {
        width: 28px;
        transform: rotate(45deg);
      }

      #menu:checked + .icon .menu::after {
        width: 28px;
        transform: rotate(-45deg);
      }

      #menu:checked + .icon .menu::before,
      #menu:checked + .icon .menu::after {
        top: 0;
        transition: top ease 0.3s, transform ease 0.3s 0.3s;
      }
    }
  }
  .menu-content-mobile {
    .collapse-icon {
      margin-top: 35px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      transform: scale(-1);

      input#menu {
        display: none;
      }

      .icon {
        width: 34px;
        height: auto;
        padding: 0;
        display: block;
        cursor: pointer;
      }

      .icon .menu,
      .icon .menu::before,
      .icon .menu::after {
        background: #0093e5;
        content: '';
        display: block;
        height: 3px;
        position: absolute;
        transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
      }
      .icon .menu::before {
        width: 28px;
      }
      .icon .menu {
        width: 24px;
      }
      .icon .menu::after {
        width: 34px;
      }

      .icon:hover .menu,
      .icon:hover .menu::before,
      .icon:hover .menu::after {
        background: #0093e5;
      }

      .icon .menu::before {
        top: -8px;
      }

      .icon .menu::after {
        top: 8px;
      }

      #menu:checked + .icon .menu {
        background: transparent;
      }

      #menu:checked + .icon .menu::before {
        width: 28px;
        transform: rotate(45deg);
      }

      #menu:checked + .icon .menu::after {
        width: 28px;
        transform: rotate(-45deg);
      }

      #menu:checked + .icon .menu::before,
      #menu:checked + .icon .menu::after {
        top: 0;
        transition: top ease 0.3s, transform ease 0.3s 0.3s;
      }
    }
  }
}
