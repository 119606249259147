@import 'assets/css/variables.scss';
.form-change-password {
  z-index: 2222;
  max-width: 100vw;
  padding-bottom: 0;
  margin: 0 auto;
  @include media-up(lg) {
    position: relative;
    max-width: 580px;
    width: 100%;
  }

  .form-change-password-heading {
    font-size: 24px;
    @include media-up(lg) {
      font-size: 32px;
    }

    text-align: center;
    margin: 24px;
    font-weight: 400;
  }
  .verify-input {
    .verify-error {
      color: #ff4d4f;
    }
  }
  .ant-modal-content {
    border: 1px solid #003e9d;
    border-radius: 30px !important;
    @include media-up(lg) {
      border-radius: 12px !important;
    }
    .ant-modal-body {
      padding: 48px 0 24px;
      .ant-form.ant-form-vertical .ant-row.ant-form-item.form-item-input {
        padding: 0 !important;
      }
      @include media-up(sm) {
        padding: 46px 48px 24px;
      }
      @include media-up(lg) {
        padding: 64px 48px 24px;
      }
      .ant-form-vertical {
        .form-item-input {
          .ant-form-item-label {
            padding: 0;
          }
        }
      }
    }
  }
}
.ant-modal.custom-modal.form-change-password {
  transform: none;
  padding: 8px;
  max-height: 100vh;
  height: auto !important;
  @include media-up(xm) {
    padding: 0 24px;
  }
  @include media-up(sm) {
    padding: 0 96px;
  }
  @include media-up(lg) {
    padding: 0 16px;
  }
  .form-item-input label span {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #003e9d;
    @include media-up(sm) {
      font-size: 20px;
    }
  }
  .button-primary {
    width: 110px;
    @include media-up(md) {
      width: 150px;
    }
  }
}
