@import 'assets/css/variables.scss';

.auth-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  // min-height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  overflow-x: hidden;
  margin-top: -80px;

  @include media-up(lg) {
    min-height: calc(100vh - 200px);
    overflow: hidden;
    margin: 0;
  }
  .auth-content-left {
    width: 100%;
    min-height: unset;
    position: relative;

    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    &::after {
      content: '';
      position: absolute;
      top: 40%;
      left: 44%;
      transform: translate(-120%, -65%);
      width: 600px;
      height: 200px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 217, 245, 0.24) 0%,
        rgba(0, 245, 160, 0.32) 0.01%,
        rgba(0, 245, 160, 0.2) 28.12%,
        rgba(0, 245, 160, 0) 100%,
        rgba(0, 245, 160, 0) 100%
      );
      transform: matrix(-1, 0, 0, 1, 0, 0);
      pointer-events: none;
      display: none;
      @include media-up(lg) {
        transform: translate(-110%, -105%);
        height: 600px;
      }
    }
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: -26%;
    //   left: 50%;
    //   transform: translate(-50%, 0);

    //   width: 800px;
    //   height: 800px;
    //   background: radial-gradient(
    //     50% 50% at 50% 50%,
    //     rgba(0, 217, 245, 0.24) 0%,
    //     rgba(0, 245, 160, 0.32) 0.01%,
    //     rgba(0, 245, 160, 0.2) 28.12%,
    //     rgba(0, 245, 160, 0) 100%,
    //     rgba(0, 245, 160, 0) 100%
    //   );
    //   opacity: 0.6;
    //   pointer-events: none;
    //   @include media-up(lg) {
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-100%, -100%);
    //     width: 800px;
    //     height: 800px;
    //   }
    // }

    @include media-up(md) {
      //min-height: 100vh;
    }
    @include media-up(lg) {
      width: 40%;
      display: flex;
      // padding-right: 8%;
    }
    // @include media-up(xxl) {
    //   width: 50%;
    // }

    .auth-content-img {
      margin-top: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      @include media-up(lg) {
        // padding-top: 100px;
        justify-content: center;
        height: 60%;
        margin-top: 60px;
      }
      img {
        // max-width: 380px;
        max-width: 320px;
        height: auto;
        object-fit: contain;

        @include media-up(md) {
          // max-width: 520px;
          max-width: 420px;
        }
        @include media-up(lg) {
          // max-width: 520px;
          max-width: 600px;
        }
      }
    }

    .auth-actions {
      width: 100%;
      height: 40%;
      display: block;
      @include media-up(lg) {
        display: none;
        padding-top: 50px;
      }
      & > a {
        display: block;
        width: 100%;
        max-width: 300px;
        margin: 20px auto;
        text-align: center;
      }
    }
  }
  .auth-content-right {
    width: 100%;
    max-width: 100%;
    // overflow: hidden;
    z-index: 0;
    position: absolute !important;
    right: 0;
    //top: 65%;
    top: 55%;
    // min-height: 65%;

    border-radius: 36px 36px 0px 0px;

    // @include media-up(xs) {
    //   top: 40%;
    // }
    // @include media-up(xm) {
    //   top: 32%;
    // }
    @include media-up(lg) {
      min-height: unset;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      // width: 100%;
      max-width: 800px;
      //min-height: 100vh !important;
      border-radius: 36px 0px 0px 36px;
    }
    @include media-up(xl) {
      width: 60% !important;
      max-width: 100%;
    }
    // @include media-up(xxl) {
    //   width: 50% !important;
    //   max-width: 100%;
    // }

    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 30%;
    //   left: 54%;
    //   transform: translate(-50%, -50%);

    //   width: 400px;
    //   height: 400px;
    //   background: linear-gradient(90deg, #00F5A0 0%, #00D9F5 100%);
    //   opacity: 0.2;
    //   filter: blur(100px);
    //   pointer-events: none;

    //   display: none;
    //   @include media-up(md) {
    //     display: block;
    //   }
    // }
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 80%;
    //   left: 68%;

    //   transform: translate(0%, -147%);
    //   width: 1000px;
    //   height: 1000px;
    //   background: radial-gradient(
    //     50% 50% at 50% 50%,
    //     rgba(0, 217, 245, 0.24) 0%,
    //     rgba(0, 245, 160, 0.32) 0.01%,
    //     rgba(0, 245, 160, 0.2) 28.12%,
    //     rgba(0, 245, 160, 0) 100%,
    //     rgba(0, 245, 160, 0) 100%
    //   );
    //   transform: matrix(-1, 0, 0, 1, 0, 0);
    //   pointer-events: none;

    //   display: none;
    //   @include media-up(lg) {
    //     transform: translate(-23%, -24%);
    //     opacity: 0.9;
    //     display: block;
    //   }
    // }

    .auth-body-right {
      width: 100%;
      height: 100%;
      // display: flex;
      // flex-direction: column;
      // overflow-x: hidden;
      // overflow-y: auto;
      display: none;
      @include media-up(lg) {
        display: block;
      }
    }
  }
}
