@import 'assets/css/variables.scss';

.authen-code {
  max-width: 674px;
  // height: 620px;
  height: 240px;
  background: #ffffff;
  border-radius: 30px;
  //padding: 120px 80px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include media-up(sm) {
    height: 380px;
    padding: 24px 60px;
  }
  @include media-up(lg) {
    // padding-top: 100px;
    //margin-top: 100px;
    height: 620px;
  }

  .authen-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #003e9d;
    text-transform: capitalize;

    @include media-up(sm) {
      margin-bottom: 40px;
      font-size: 32px;
    }
  }
  .authen-des {
    color: #e5e6ed;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  .verify-resend {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;

    > div,
    > a {
      color: #14d19d;

      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      @include media-up(sm) {
        font-size: 16px;
      }
    }
    a:hover {
      opacity: 0.7;
    }
    a:active {
      opacity: 1;
    }
  }
  .authen-error {
    color: $warning;
    font-size: 13px;
    text-align: center;
    padding: 20px 10px;
    @include media-up(sm) {
      font-size: 16px;
    }
  }
  .authen-action {
    width: 100%;
    text-align: center;
    margin-top: 32px;

    .button-primary {
      width: 100%;
      max-width: 110px;
      @include media-up(sm) {
        max-width: 150px;
      }
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    a {
      color: #00f4a3;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .authen-exclamation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    padding: 20px;
    margin-top: 40px;

    background: #141518;
    border-radius: 10px;

    svg {
      margin-right: 10px;
    }
    p {
      color: #a5adcf;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
