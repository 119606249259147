@import 'assets/css/variables.scss';
.wPage {
  float: right;
  width: 100%;
  max-width: 1000px;
  //margin: 0 auto;
  //min-height: calc(100vh - 200px - 70px);
  min-height: 844px;
  //height: 100vh;
  padding: 0 16px;
  overflow: hidden;
  @include media-down(xs) {
    padding: 10px;
  }
}
