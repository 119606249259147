@import 'assets/css/variables.scss';

.wrap-card-ref {
  .box-info-sponserkey {
    .networks-ref {
      h3 {
        font-weight: 700;
        font-size: 16px;
        @include media-up(md) {
          font-size: 24px;
        }
      }
      .input-coppy-wrapper {
        .wrapper-input {
          height: 44px;
          background: #f2faff;
          @include media-up(md) {
            height: 50px;
          }
          border: 0.5px solid $border-input;
          border-radius: 8px;
          .ant-input-affix-wrapper {
            padding: 0;
          }
          input.ant-input {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #003e9d;
            @include media-up(md) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
