.point-item {
  margin-right: 8px;
  width: 20px;
  @media (min-width: 991px) {
    width: 40px;
  }
  &.first-point {
    margin-left: 8px;
  }

  &:last-child {
    margin-right: 0;
  }
}
