@import 'assets/css/variables.scss';
.package-payment-wrapper {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  @include media-down(sm) {
    padding: 16px;
    border: 1px solid rgba(1, 54, 130, 0.5);
    border-radius: 8px;
  }

  .package-top {
    display: flex;

    justify-content: space-between;
    &_left {
      display: flex;
      gap: 32px;
      @include media-down(700px) {
        gap: 20px;
      }
      @include media-down(sm) {
        gap: 16px;
      }
      &_img {
        width: 93px;
        height: 93px;
        background: #003e9d;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-down(sm) {
          width: 61px;
          height: 61px;
        }
        img {
          width: 80px;
          height: 80px;
          @include media-down(sm) {
            width: 51px;
            height: 51px;
          }
        }
      }
      &_detail {
        display: flex;
        flex-direction: column;
        gap: 8px;
        span {
          color: #003e9d;
        }
        &_title {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          @include media-down(700px) {
            font-size: 18px;
            white-space: nowrap;
          }
          @include media-down(sm) {
            font-size: 12px;
            line-height: 16px;
          }
        }
        &_value {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          @include media-down(700px) {
            font-size: 18px;
          }
          @include media-down(sm) {
            font-size: 16px;
            line-height: 21px;
          }
        }
        &_desc {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          @include media-down(700px) {
            font-size: 16px;
          }
          @include media-down(sm) {
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
          }
        }
      }
    }

    &_button {
      display: flex;
      align-items: center;
      .ant-btn {
        border: none;
        color: #ffffff;
        width: 143px;
        height: 48px;
        background: #27b38c;
        box-shadow: 0px 4px 10px rgba(23, 105, 201, 0.25);
        border-radius: 12px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-down(md) {
          width: 120px;
        }
        @include media-down(700px) {
          width: 110px;
          //height: 32px;
        }

        @include media-down(sm) {
          border-radius: 6px;
          width: 80px;
          height: 32px;
          font-size: 8px;
        }
      }
    }
  }

  .package-info {
    width: 283px;
    margin-left: 79px;
    margin-top: 48px;
    //display: none;
    //transition: all ease-in-out 2s;
    // transform: translateX(10px);
    max-height: 0px;
    transition: all ease-in-out 0.4s;
    overflow: hidden;
    @include media-down(md) {
      width: 100%;
      margin-left: 0;
      //height: 32px;
    }
    &.active {
      //display: block;
      //transition: all ease-in-out 2s;
      //animation: abc ease-in-out 2s;
      //   transform: translateY(200px);
      //   transition: all ease-in-out 2s;
      max-height: 999px;
    }
    ul {
      li {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #003e9d;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-down(sm) {
          font-size: 14px;
          line-height: 19px;
        }
        span {
          width: 22px;
          height: 22px;
          background: #003e9d;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      li:last-child {
        margin: 0;
      }
    }
  }
  .package-expland {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}
