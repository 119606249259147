@import 'assets/css/variables.scss';

a.back-link {
  &:hover {
    color: darken($color: #ffffff, $amount: 20);
    fill: darken($color: #ffffff, $amount: 20);
  }
  &:active {
    color: #ffffff;
    fill: #ffffff;
  }
}
.back-link {
  color: #ffffff;
  fill: #ffffff;
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .back-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
    @include media-up(sm) {
      margin-right: 20px;
    }
    svg {
      width: 20px;
      @include media-up(sm) {
        width: 30px;
      }
    }
  }
  .back-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 100%;
    text-transform: capitalize;

    @include media-up(sm) {
      font-size: 48px;
      line-height: 44px;
    }
  }
}
