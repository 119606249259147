@import 'assets/css/variables.scss';

.select-show-history {
  .ant-select-item .ant-select-item-option-content {
    padding: 0;
  }
}

.pagination-wrapper {
  display: flex;
  width: 100%;

  .show-record {
    width: 210px;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    .ant-select-item-option-content {
      padding: 20px;
    }
    span.show {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #003e9d;
      margin-right: 8px;
    }

    span.records {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #003e9d;
      margin-left: 8px;
    }

    .ant-select-single .ant-select-selector {
      width: 73px;
      height: 29px;
      border: 1px solid #000000;
      border-radius: 4px;
    }

    .ant-select-single .ant-select-selection-item {
      line-height: 24px;
    }
  }

  .pagination {
    width: 100%;
    justify-content: end;
    display: flex;

    &.have-show-records {
      width: calc(100% - 210px);
    }
    .pagi-item {
      background: none;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #003e9d;
      }
    }
    div,
    button {
      background-color: #eeeeee;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      height: 26px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var(--text);
      padding: 4px 10px;
    }

    .first {
      width: 46px;
      cursor: pointer;
    }

    .previous {
      width: 26px;
      margin: 0 8px;
      cursor: pointer;
    }

    .number {
      margin-left: 12px;
      width: 41px;
      height: 29px;
      background: #003e9d;
      border-radius: 4px;
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;

        color: #ffffff;
      }
    }

    .next {
      width: 26px;
      margin: 0 8px;
      cursor: pointer;
    }

    .last {
      width: 46px;
      cursor: pointer;
    }
  }

  @include media-down(sm) {
    display: block;

    .show-record {
      text-align: center;
    }

    .pagination {
      justify-content: center;
    }
  }
}
