@import 'assets/css/variables.scss';

.wallet-wrapper {
  //display: flex;

  .referral-link {
    margin-top: 32px;
    background: #ffffff;
    border: 0.5px solid rgba(1, 54, 130, 0.5);
    border-radius: 12px;
    padding: 24px 32px;
    @include media-down(sm) {
      padding: 16px;
    }
    .wrap-card-ref .box-info-sponserkey .networks-ref .input-coppy-wrapper .wrapper-input {
      background: #f2faff;
    }
    .input-coppy-wrapper input.ant-input {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #003e9d;
      @include media-down(sm) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .input-coppy-wrapper h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #003e9d;
      @include media-down(sm) {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
      }
    }
  }
  .wallet-top-content {
    display: flex;
    gap: 32px;
    @include media-down(xl) {
      // flex-direction: column;
      // gap: 24px;
      display: none;
    }
  }
  .wallet-top-mobile {
    display: none;
    @include media-down(xl) {
      display: block;
    }
    .balance-mobile {
      display: flex;
      gap: 32px;
      margin-bottom: 32px;
      @include media-down(sm) {
        gap: 8px;
      }
      .balance-card {
        @include media-down(sm) {
          padding: 12px 0;
        }
        img {
          @include media-down(md) {
            width: 42px;
            height: 42px;
          }
          &[data-status] {
            @include media-down(md) {
              width: 25px;
              height: 42px;
            }
          }
        }
        .balance-card-currency .balance-card-currency-content {
          font-size: 24px;
          line-height: 140%;
        }
        .balance-card-header .balance-card-header-content p {
          font-size: 12px;
          line-height: 140%;
        }
      }
    }
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    margin-bottom: 0;

    color: #003e9d;
    @include media-down(lg) {
      font-size: 16px;
      line-height: 21px;
    }
    @include media-down(sm) {
      color: #0f1b36;
    }
  }
  .time-picker-div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    .datetime-picker {
      .ant-picker-suffix {
        img {
          width: 22px;
          height: 22px;
        }
      }
      .ant-picker-input:first-child > input {
        text-align: right;
      }
      .ant-picker-input > input {
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #000000;
      }
      .ant-picker-clear {
        right: 15px;
      }
      .ant-picker-active-bar {
        opacity: 0;
      }
      .ant-picker-focused {
        box-shadow: none;
      }
    }
    img.calender {
      position: absolute;
      width: 22px;
      height: 22px;
      z-index: 3;
      top: 5px;
      right: 215px;
      @include media-down(sm) {
        top: 42px;
        left: 12px;
      }
    }
    .ant-picker {
      padding-left: 44px;
    }
    @include media-down(sm) {
      gap: 16px;
      flex-direction: column;
      align-items: start;
    }
  }
  .ant-empty-description {
    color: #000000;
  }
  .wallet-history-pagination {
    padding: 8px 32px;
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    > div {
      border-top: 0.5px solid #003e9d;
      padding: 8px 0;
    }
  }
}
