@import 'assets/css/variables.scss';

$panel-width: 350px;

.private-layout-container {
  padding: 48px 75px;
  width: 100%;
  min-height: 100vh;
  // display: flex;
  max-width: 1440px;
  margin: 0 auto;
  flex-flow: row nowrap;
  @include media-down(lg) {
    padding: 48px 32px;
  }
  @include media-down(sm) {
    padding: 36px 24px;
  }
  @include media-down(380px) {
    padding: 36px 12px;
  }
  .collapse-icon {
    display: flex;
    align-items: center;
    transform: scale(-1);
    z-index: 11;
    input#menu {
      display: none;
    }

    .icon {
      width: 34px;
      height: auto;
      padding: 0;
      display: block;
      cursor: pointer;
    }

    .icon .menu,
    .icon .menu::before,
    .icon .menu::after {
      background: #fff;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
    }
    .icon .menu::before {
      width: 28px;
    }
    .icon .menu {
      width: 24px;
    }
    .icon .menu::after {
      width: 34px;
    }

    .icon:hover .menu,
    .icon:hover .menu::before,
    .icon:hover .menu::after {
      background: #fff;
    }

    .icon .menu::before {
      top: -8px;
    }

    .icon .menu::after {
      top: 8px;
    }

    #menu:checked + .icon .menu {
      background: transparent;
    }

    #menu:checked + .icon .menu::before {
      width: 28px;
      transform: rotate(45deg);
    }

    #menu:checked + .icon .menu::after {
      width: 28px;
      transform: rotate(-45deg);
    }

    #menu:checked + .icon .menu::before,
    #menu:checked + .icon .menu::after {
      top: 0;
      transition: top ease 0.3s, transform ease 0.3s 0.3s;
    }
  }
  .header {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include media-up(sm) {
      height: 98px;
    }
    .panel-logo {
      width: 145px;
      height: 27px;
      @include media-up(xm) {
        width: 185px;
        height: 32.27px;
      }
      @include media-up(md) {
        width: 264px;
        height: 46.05px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .panel-content {
    display: flex;
    justify-content: space-between;
    .panel-container {
      // position: fixed;
      left: 0;
      top: 0;
      bottom: 0;

      // width: $panel-width;
      // background: rgba(62, 60, 60, 0.6);
      // backdrop-filter: blur(4px);
      // border-radius: 0 12px 12px 0;
      width: 220px;
      display: none;
      @include media-up(lg) {
        display: block;
      }

      .panel-body {
        background-color: #ffffff;
        border-radius: 16px;
        width: 100%;
        // height: 772px;
        // max-width: 220px;
        padding: 48px 24px 92px;

        // margin: 0 auto;

        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        gap: 70px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -100px;
          bottom: 3%;

          width: 300px;
          height: 300px;
          background: linear-gradient(91deg, #ffffff 0%, #086afc 100%);
          opacity: 0.5;
          filter: blur(200px);
          pointer-events: none;
          z-index: 1;
        }

        .panel-top {
          .panel-logo {
            padding: 20px 0;
            cursor: pointer;
            img {
              // width: 100%;
              max-width: 264px;
              object-fit: contain;
              margin-right: 10px;
            }
          }
          ul.panel-link {
            list-style: none;
            display: flex;
            flex-direction: column;
            row-gap: 32px;

            // margin-top: 50px;

            li {
              a {
                color: #003e9d;
                //fill: #003e9d;
                align-items: center;
                font-weight: 500;
                font-size: 18px;
                line-height: 19px;

                font-style: normal;

                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;

                padding: 12px 16px;

                svg {
                  //fill: #003e9d;
                  //stroke: #003e9d;
                  width: 20px;
                  margin-right: 16px;
                }
              }
            }
            li.active,
            li:hover {
              // &:first-child {
              //   svg {
              //     path {
              //       // stroke: none !important;
              //       &:first-child {
              //         stroke: #0f1b36;
              //         fill: none;
              //       }
              //       &:last-child {
              //         stroke: #0f1b36;

              //         // fill: #0f1b36;
              //       }
              //     }
              //   }
              // }
              a {
                color: #0f1b36;
                //fill: #0f1b36;
                background: #a6cbff;
                box-shadow: 0px 4px 10px rgba(175, 137, 255, 0.15);
                border-radius: 8px;
                font-weight: 700;
                font-size: 18px;
                line-height: 19px;
              }
              svg {
                path {
                  stroke: #0f1b36;
                  //fill: #003e9d;
                  &:first-child {
                    stroke: #0f1b36;
                    //fill: #0f1b36;
                  }
                  &:last-child {
                    stroke: #0f1b36;
                    //fill: #0f1b36;
                  }
                }
              }
            }
          }
        }
        .panel-footer {
          margin-bottom: 20px;
          h5 {
            color: #ffffff;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
          p {
            color: #bfbfbf;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-top: 10px;
          }
          &_img {
            width: 172px;
            height: 172px;
            border-radius: 50%;
            //background: black;
            img {
              width: 172px;
              height: 158.49px;
            }
          }
          &_img::before {
            content: '';
            position: absolute;
            width: 172px;
            height: 172px;
            border-radius: 50%;
            margin: -10px;
            background: linear-gradient(45deg, transparent, transparent 40%, #a6cbff);
            filter: blur(25px);
            // z-index: -1;
            animation: animate 3s infinite linear;
          }
          @keyframes animate {
            0% {
              transform: rotateZ(0deg);
            }
            100% {
              transform: rotateZ(360deg);
            }
          }
          &_line {
            margin-top: 60px;
            height: 2px;
            background: #a6cbff;
            border-radius: 4px;
          }
          &_logout {
            margin-top: 50px;
            margin-left: 16px;
            display: flex;
            flex-direction: row;
            gap: 16px;
            svg {
              cursor: pointer;
            }
            &-txt {
              cursor: pointer;
              span {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #003e9d;
              }
            }
          }
          .connect-now {
            button {
              fill: #0f1b36;
              color: #0f1b36;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;

              width: 100%;
              height: 40px;
              margin-top: 16px;
              background: #ffffff;
              border-radius: 8px;
              border: unset;
              cursor: pointer;

              display: flex;
              text-align: center;
              align-items: center;
              justify-content: center;

              &:hover {
                opacity: 0.7;
              }
              &:active {
                opacity: 1;
              }

              svg {
                margin-right: 8px;
              }
            }
          }

          .term-privacy {
            color: #b9b9b9;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: block;
            margin-top: 20px;
            max-width: 120px;
            &:hover {
              text-decoration: underline;
            }
          }

          .version {
            color: #b9b9b9;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            margin-top: 20px;
          }
        }
      }
    }

    .private-content {
      //display: flex;
      //justify-content: flex-end;
      width: 100%;
      //height: 100vh;
      margin-left: auto;
      padding-bottom: 125px;

      position: relative;
      overflow: auto;

      @include media-up(lg) {
        // width: calc(100% - $panel-width);
        padding-bottom: 0;
      }
    }
  }
}
