@import 'assets/css/variables.scss';

.auth-card.verify-code.fadeIn.login {
  background: #ffffff;
  border-radius: 4px;
  .verify-resend {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    .verify-resend-login {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #003e9d;
    }

    > div,
    > a {
      color: #003e9d;

      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      @include media-up(sm) {
        font-size: 16px;
      }
    }
    a:hover {
      opacity: 0.7;
    }
    a:active {
      opacity: 1;
    }
  }
  .verify-title {
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    color: #003e9d;

    @include media-up(sm) {
      font-size: 28px;
    }
    @include media-up(xs) {
      font-size: 24px;
    }
  }
  .button-primary {
    width: 110px;
    @include media-up(sm) {
      width: 150px;
    }
  }
  .input-code-container > div .input-code-item input {
    width: 59px !important;
    @include media-down(sm) {
      width: 50px !important;
      height: 54px;
    }
    @include media-down(xm) {
      width: 41px !important;
      height: 50px;
    }
  }
}

.verify-code {
  max-width: 550px;
  @include media-up(lg) {
    // max-height: 550px;
    // margin-top: 120px;
  }

  .verify-error {
    color: $warning;
    font-size: 13px;
    text-align: center;
    padding: 20px 10px;
    @include media-up(sm) {
      font-size: 16px;
    }
  }
  .verify-action {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    row-gap: 20px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      a {
        color: #00f4a3;
        font-size: 14px;
        font-weight: 600;
      }
    }
    button {
      max-width: 120px;
    }
  }
}
