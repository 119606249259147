@import 'assets/css/variables.scss';

.profile-account {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  & > .background-cover {
    width: 48px;
    height: 48px;
    padding: 2px;
    border-radius: 100%;
    background: linear-gradient(155deg, #db242d 12.37%, #ffe5d6 87.63%);
    overflow: hidden;
    display: block;
    @include media-down(lg) {
      display: flex;
      align-items: center;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    @include media-down(sm) {
      display: block;
      svg {
        width: 17px;
        height: 15px;
      }
    }
    &.menu-icon {
      background: none !important;
      border-radius: 0 !important;
    }
    @media (min-width: 768px) {
      margin-right: 10px;
    }
    @include media-down(sm) {
      width: 20px;
      height: 20px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  & > span {
    color: #003e9d;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.dropdown-logout {
  background: #0093e5;
  border-radius: 12px;
  padding: 3px 0;
  @include media-down(sm) {
    border-radius: 8px;
  }

  display: flex;
  align-items: center;
  flex-direction: column;

  a {
    fill: #fff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:not(:first-child):not(:last-child) {
      border-top: 1px solid rgba(214, 198, 198, 0.2);
    }
    &:last-child {
      border-top: 2px solid rgba(58, 36, 36, 0.2);
    }

    &:hover {
      fill: rgb(154, 190, 239);
      color: rgb(154, 190, 239);
    }

    svg {
      max-width: 18px;
      margin-right: 10px;
    }
  }
}
