@import 'assets/css/variables.scss';

.columns-title {
  //text-align: left;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #003e9d;
  &.left-align {
    text-align: left;
  }
  &.center-align {
    text-align: center;
  }
}
.columns-txt {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #003e9d;
  &.txt-col {
    display: flex;
    flex-direction: column;
  }
  @include media-down(md) {
    font-size: 14px;
  }
  &.centered {
    text-align: center;
  }
  &.actions {
    color: #be0000;
    font-weight: 700;
    margin-left: 16px;
  }
}
