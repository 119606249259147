@import 'assets/css/variables.scss';

.notification-wrapper {
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 30px;
  padding: 32px;
  margin-top: 32px;
  background: #ffffff;
  @include media-down(md) {
    padding: 24px;
  }
  @include media-down(sm) {
    padding: 24px 16px;
    border-radius: 12px;
  }
  @include media-down(330px) {
    padding: 16px 8px;
  }

  &_title {
    margin-bottom: 64px;
    @include media-down(sm) {
      margin-bottom: 32px;
    }
    span {
      font-weight: 700;
      font-size: 32px;
      line-height: 43px;
      color: #003e9d;
      @include media-down(md) {
        font-size: 28px;
      }
      @include media-down(sm) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
  .noti-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #003e9d;
    @include media-down(md) {
      font-size: 22px;
    }
    @include media-down(sm) {
      font-size: 14px;
      line-height: 19px;
    }
  }
  .noti-desc {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #003e9d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include media-down(md) {
      font-size: 22px;
    }
    @include media-down(sm) {
      font-size: 14px;
      line-height: 19px;
    }
  }
  &_content {
    .ant-row {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
    }
    .ant-row:last-child {
      margin-bottom: 0;
    }

    .noti-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #003e9d;
      border-radius: 8px;
      color: #ffffff;
      height: 56px;
      width: 100%;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      span {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          @include media-down(sm) {
            width: 14px;
            height: 14px;
          }
        }
      }
      @include media-down(md) {
        font-size: 20px;
      }
      @include media-down(sm) {
        height: 35px;
        font-size: 14px;
        line-height: 19px;
      }
      @include media-down(330px) {
        height: 32px;
        font-size: 12px;
        line-height: 19px;
      }
    }
  }
}
