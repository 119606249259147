@import 'assets/css/variables.scss';

.public-layout-container {
  max-width: 1290px;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  background: $secondary;

  .desktop-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    z-index: 10;

    display: none;
    @include media-up(lg) {
      display: flex;
      max-width: 1290px;
    }

    .header-left {
      .header-logo {
        position: absolute;
        left: 96px;
        top: 30px;
        cursor: pointer;
        img {
          width: 100%;
          max-width: 200px;
          object-fit: contain;
          margin-right: 10px;
          @include media-up(xl) {
            max-width: 264px;
          }
        }
      }
    }
    .header-right {
      height: 100%;
      display: flex;
      flex-flow: row nowrap;

      position: absolute;
      right: 96px;
      top: 27px;
      .btn-unActions {
        &:hover {
          //background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
          // border: 1px solid linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
          background: #ffffff;
          opacity: 0.8;
          div {
            //background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
            color: #003e9d;
            background: #ffffff;
            //opacity: 0.8;
            span {
              color: unset;
              background: unset;
              -webkit-background-clip: unset;
              -webkit-text-fill-color: unset;
            }
          }
        }
      }
    }
  }
}
