.project-list-wrapper {
  .my-project {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    color: #000000;
    margin: 32px 0;
  }
  .project-list-content {
    gap: 32px;
    display: flex;
    flex-direction: column;
  }
}
