.pending-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  position: relative;
  top: 15%;
  img {
    width: 100px;
    height: 100px;
  }
  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #003e9d;
  }
  .loading-style {
    width: 100px;
    height: 100px;
  }
}
