@import 'assets/css/variables.scss';
.warp-title-section {
  margin-bottom: 32px;
  text-align: center;
  @include media-down(sm) {
    margin-bottom: 16px;
  }

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 44.8px;
    color: #003e9d;
    @include media-down(sm) {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }

  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 0;
    @include media-down(md) {
      font-size: 14px;
    }
  }

  // @media (min-width: 991px) {
  //   margin-bottom: 34px;

  //   h2 {
  //     font-size: 32px;
  //     // line-height: 72px;
  //     margin-bottom: 8px;
  //   }

  //   p {
  //     font-size: 20px;
  //     line-height: 30px;
  //   }
  // }
}
