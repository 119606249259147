@import 'assets/css/variables.scss';

.button-secondary {
  font-size: 72px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  height: 47px;
  width: 100%;
  padding: 1px;

  background: transparent;

  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  max-width: 120px;
  font-family: 'Exo';
  border-radius: 8px;
  svg {
    transform: translate(-9px, 4px);
    path {
      fill: #003e9d;
    }
  }
  div {
    background: transparent;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid #003e9d;
  }
  &[data-size='small'] {
    font-size: 10px;
    line-height: 20px;
    height: 20px;
    @include media-up(sm) {
      font-size: 12px;
      height: 26px;
    }
  }
  &[data-size='standard'] {
    font-size: 14px;
    line-height: 22px;
    height: 42px;
    @include media-up(sm) {
      font-size: 16px;
      height: 46px;
      max-width: 150px;
    }
  }
  &[data-size='larger'] {
    font-size: 16px;
    line-height: 24px;
    height: 56px;
    @include media-up(sm) {
      font-size: 18px;
      height: 66px;
    }
  }

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 1;
  }

  &[disabled],
  &[data-loading='true'] {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &[data-hoverable='false'] {
    opacity: 1;
    cursor: default;
  }

  .ant-spin {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
