@import 'assets/css/variables.scss';

.ant-modal.custom-modal.modal-history-details {
  transform: none;
  @include media-down(770px) {
    padding: 24px;
  }
}
.modal-history-details {
  max-width: 100vw;
  margin: 0 auto;
  height: auto !important;
  padding-bottom: 0;
  .history-detail-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    top: -10px;
    position: relative;
    color: #003e9d;
    @include media-down(sm) {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
    }
  }
  .ant-modal-content {
    background: #ffffff;
    padding: 40px 15px;
    border-radius: 30px;
    @include media-down(sm) {
      border-radius: 15px;
    }

    @media (min-width: 768px) {
      border-radius: 12px;
      padding: 47px 50px;
    }
    .ant-modal-header {
      padding: 0;
      padding-left: 0;
      margin-bottom: 16px;
    }

    .ant-modal-body {
      padding: 0;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        &.modal-history-details-steps {
          counter-reset: section;

          li {
            position: relative;
            min-height: 52px;
            padding-left: 45px;
            display: flex;
            flex-direction: column;

            p {
              margin-bottom: 0;

              color: #ffffff;
              text-align: left;

              font-weight: 700;
              font-size: 18px;
              line-height: 24px;

              color: #282828;
              @media (min-width: 768px) {
                font-size: 16px;
              }
              &:last-child {
                padding-bottom: 14px;
                font-weight: 400;
                font-size: 16px;
                line-height: 27px;
                color: #282828;
              }
            }

            &::before {
              counter-increment: section;
              content: counter(section);
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
              height: 22px;
              width: 22px;
              background: #003e9d;
              display: flex;
              justify-content: center;
              border-radius: 50%;
              position: absolute;
              z-index: 1;
              left: 0;
              top: 0;
            }

            &:last-child {
              p {
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
        }

        @media (min-width: 991px) {
          &.modal-history-details-steps {
            counter-reset: section;

            li {
              p {
                font-size: 18px;
                line-height: 27px;

                &:last-child {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .modal-history-details-note {
        margin-bottom: 16px;
        p {
          // font-weight: 500;
          // font-size: 14px;
          // line-height: 150%;
          // margin-bottom: 0;
          // text-align: left;
          // padding-left: 45px;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;

          &:first-child {
            color: #282828;
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 27px;
            text-decoration-line: underline;
            color: #003e9d;
          }
        }

        @media (min-width: 991px) {
          p {
            font-size: 18px;
            line-height: 27px;
            &:last-child {
              font-size: 18px;
            }
          }
        }
      }

      .modal-history-details-info {
        .modal-history-details-info-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;
          &-address {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;

            text-align: right;
            text-decoration-line: underline;

            color: #003e9d !important;
          }
          p {
            // font-weight: 500;
            // font-size: 14px;
            // line-height: 24px;
            // color: #ffffff;
            // margin-bottom: 0;
            &[data-status='COMPLETED'] {
              font-weight: 500;
              font-size: 18px;
              line-height: 23px;
              color: #27b38c !important;
            }
            &:first-child {
              text-align: left;
              margin-right: 16px;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              color: #282828;
            }

            &:last-child {
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              text-align: right;

              color: #282828;
            }
            span,
            a {
              color: #389201;
              font-weight: 700;
            }
          }

          &:nth-child(2) {
            p {
              &:last-child {
                font-weight: 500;
              }
            }
          }
          &:nth-child(3) {
            p {
              &:last-child {
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
              }
            }
          }

          &:nth-child(4) {
            p {
              &:last-child {
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;

            p {
              &:last-child {
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
                word-break: break-all;
                word-wrap: break-word;
                text-align: right;
                cursor: pointer;
              }
            }
          }
        }

        @media (min-width: 991px) {
          .modal-history-details-info-item {
            margin-bottom: 6px;

            p {
              font-size: 14px;
              line-height: 24px;

              &:first-child {
                font-size: 18px;
              }
              &:last-child {
                a {
                  color: #389201;
                }
              }
            }

            &:nth-child(5) {
              p {
                &:last-child {
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
    }

    .modal-scan-action-bg {
      position: absolute;
      height: 70%;
      width: 70%;
      // background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
      filter: blur(250px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
    }

    @media (min-width: 991px) {
      padding: 32px;
    }
  }
}
.ant-modal-wrap {
  top: unset;
  overflow: hidden;
  // padding-bottom: 50px;
  @media (min-width: 768px) {
    top: 0;
    overflow: hidden;
    padding-bottom: 0;
  }
}
