.itemData {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 24px 0px 8px;
  border-bottom: 1px solid #000000;
  h2 {
    width: 186px;
    font-size: 16px;
    font-weight: 700;
  }
  div {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    width: 170px;
    text-align: center;
    img {
      display: inline-block !important;
    }
  }
}
