@import './mixins/class';
@import './mixins/mixins';
@import './mixins/breakpoints';

$menu-height-desktop: 100px;
$menu-height-mobile: 80px;

$text-main: #ffffff;

$primary: red;
$secondary: #f1faff;

$success: #11cabe;
$failure: #ed4b9e;
$warning: #ff4d4f;

$link: #00f4a3;

$bg-input: transparent;
$border-input: #34384c;

$background-card: #1a3035;
$linear-gradient: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
