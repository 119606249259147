@import 'assets/css/variables.scss';

.payment-method {
  width: 410px;
  padding: 32px;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(23, 105, 201, 0.25);
  border-radius: 12px;
  @include media-down(xl) {
    width: 100%;
  }
  @include media-down(sm) {
    box-shadow: none;
    padding: 16px;
  }
  &_header {
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #003e9d;
      @include media-down(sm) {
        font-size: 16px;
        line-height: 140%;
        font-weight: 700;
      }
    }
  }
  &_select {
    .ant-select {
      width: 100%;
      .ant-select-selector {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: #003e9d;
        padding-top: 11px;
        height: 53px;
        background: #f2faff;
        border: 1px solid #003e9d;
        border-radius: 8px;
        @include media-down(sm) {
          font-size: 12px;
          line-height: 16px;
          height: 44px;
          padding-top: 7px;
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #003e9d;
      margin-bottom: 8px;
      @include media-down(sm) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  &_btn {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
