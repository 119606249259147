@import 'assets/css/variables.scss';

.warp-page-settings {
  max-width: 100%;
  margin-bottom: 64px;
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 20px;
  padding: 32px;
  gap: 32px;
  @include media-down(sm) {
    padding: 24px 0;
  }
  @include media-down(md) {
    background: none;
    border: none;
  }
  .page-settings-heading {
    margin-bottom: 32px;

    @include media-down(md) {
      display: block;
    }
    @include media-down(md) {
      margin-bottom: 24px;
    }

    h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 64px;
      color: #003e9d;
      @include media-down(md) {
        font-weight: 700;
        font-size: 48px;
        line-height: 64px;
        color: #003e9d;
      }
      @include media-down(sm) {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
      }
    }
    &_info {
      display: flex;
      align-items: center;
      gap: 32px;

      @include media-down(md) {
        gap: 16px;
      }
      h2 {
        cursor: pointer;
      }
      img {
        cursor: pointer;
        width: 14px;
        height: 28px;
        margin-bottom: 24px;
        @include media-down(md) {
          width: 12px;
          height: 16px;
        }
        @include media-down(sm) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .page-settings-content {
    max-width: 1000px;
    margin: auto;
    .page-settings-content-body {
      .settings-item {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        @include media-down(md) {
          padding: 16px 32px;
          //margin-bottom: 16px;
          flex-direction: column;
          background: #ffffff;
          border-radius: 12px;
          align-items: flex-start;
        }
        @include media-down(sm) {
          padding: 8px 16px;
          margin-bottom: 16px;
          flex-direction: column;
          background: #ffffff;
          border-radius: 12px;
          align-items: flex-start;
        }
        &_span {
          @include media-down(md) {
            margin-bottom: 32px;
          }
          @include media-down(sm) {
            margin-bottom: 24px;
          }
        }
        &_button {
          @include media-down(md) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .button-secondary {
            width: 150px;
            height: 48px;
            @include media-down(md) {
              width: 120px;
              height: 40px;
            }
            @include media-down(sm) {
              width: 100px;
              height: 40px;
            }
          }
        }

        .btn-disable {
          border-color: rgba(250, 34, 86, 0.5);
          color: #fa2256;
        }

        &:last-child {
          margin-bottom: 0;
          .btn-disable {
            //background: #e42c2c;
            color: #ffffff;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-align: center;
          }
          p {
            &:first-child {
              font-weight: 700;
              font-size: 20px;
              line-height: 27px;
              color: #e42c2c;

              @include media-down(sm) {
                font-size: 14px;
                line-height: 27px;
              }
            }
            &:last-child {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #e42c2c;
              @include media-down(sm) {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #003e9d;
          @include media-down(sm) {
            font-size: 14px;
            line-height: 24px;
          }

          &:first-child {
            //font-family: 'Exo';
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: #003e9d;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
            @include media-down(sm) {
              font-weight: 700;
              font-size: 14px;
              line-height: 27px;
            }
          }
        }
        .btn-verify {
          background: #003e9d;
          color: #ffffff;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
        }

        .button-secondary div span {
          //background: linear-gradient(90deg, #fff 0%, #fff 100%);
          -webkit-text-fill-color: inherit;
        }
        .button-secondary div {
          border: none;
        }
      }
    }

    // @media (min-width: 991px) {
    //   .page-settings-content-header {
    //     padding: 33px 40px;
    //     margin-bottom: 60px;

    //     .box-kyc {
    //       padding-bottom: 20px;
    //       margin-bottom: 20px;

    //       p {
    //         font-size: 16px;

    //         &:first-child {
    //           font-size: 24px;
    //         }
    //       }
    //     }
    //     .info-kyc {
    //       .info-item {
    //         span {
    //           font-size: 14px;
    //         }
    //       }
    //     }
    //   }

    //   .page-settings-content-body {
    //     .settings-item {
    //       margin-bottom: 36px;

    //       p {
    //         font-size: 16px;
    //         line-height: 27px;

    //         &:first-child {
    //           font-size: 18px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
