@import 'assets/css/variables.scss';
.dashboard-top {
  display: flex;
  gap: 34px;
  @include media-down(md) {
    gap: 18px;
  }
  @include media-down(sm) {
    display: grid;
  }
  // @include media-down(sm) {
  //   gap: 18px;
  // }
  // @include media-down(xm) {
  //   gap: 18px;
  // }
  // @include media-between(sm, md) {
  //   gap: 18px;
  // }
  .dashboard-top-mobile {
    width: 100%;
    > div:first-child {
      display: flex;
      justify-content: center;
      gap: 32px;
      margin-bottom: 24px;
      @include media-down(sm) {
        gap: 8px;
      }
    }
    > div:last-child {
      display: flex;
      justify-content: center;
    }
    .block-content {
      width: 190px;
      height: 184px;
      padding-top: 20px;
      flex: none;
      @include media-down(sm) {
        width: 170px;
        height: 147px;
      }
      @include media-down(xm) {
        width: 142px;
        height: 147px;
      }
    }
  }
  &_mb {
    display: flex;
    width: 100%;
    gap: 34px;

    @include media-between(sm, md) {
      gap: 18px;
    }
    @include media-down(md) {
      flex-direction: column;
      .block-content {
        margin: 0 auto;
        width: 70%;
      }
    }
    @include media-down(sm) {
      gap: 18px;
    }
    @include media-down(xm) {
      gap: 16px;
    }
  }
}

.dashboard-project {
  width: 100%;
  margin-top: 24px;
}

.dashboard-line {
  height: 2px;
  margin: 24px 0;
  background: rgba(13, 110, 255, 0.5);
  border-radius: 1px;
}

.dashboard-card {
  margin-top: 24px;
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 12px;
  padding: 24px 32px;
  @include media-down(sm) {
    padding: 24px 16px;
  }
  @include media-down(xm) {
    padding: 24px 8px;
  }
  .style-input-title {
    .title {
      color: #003e9d;
    }
    margin-bottom: 8px;
  }
  .wrapper-input {
    border: 1px solid #003e9d !important;
    border-radius: 8px;
  }
}
