@import './variables.scss';
@import './font-face.scss';
@import './animation.scss';
@import './resets.scss';
@import './components.scss';
@import './global-class.scss';
* {
  font-family: 'Exo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

*::after,
*::before {
  box-sizing: border-box;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #003e9d;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:vertical {
  background: #797e86;
}

::-webkit-scrollbar-track {
  background: rgba(190, 190, 190, 0.4);
  border-radius: 3px;
  height: 50%;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: #ffffff;
  fill: #ffffff;
  // min-height: 550px;
}
.ant-scrolling-effect {
  width: 100% !important;
}
#root {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  background-color: #f1faff;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 27%;
    transform: translate(-50%, 0);

    width: 800px;
    height: 800px;
    // background: radial-gradient(50% 50% at 50% 50%, rgba(157, 220, 255, 0.3) 0%, rgba(157, 220, 255, 0) 100%);
    // background: radial-gradient(
    //   50% 50% at 50% 50%,
    //   rgba(0, 217, 245, 0.24) 0%,
    //   rgba(0, 245, 160, 0.32) 0.01%,
    //   rgba(0, 245, 160, 0.2) 28.12%,
    //   rgba(0, 245, 160, 0) 100%,
    //   rgba(0, 245, 160, 0) 100%
    // );
    // opacity: 0.6;
    pointer-events: none;
    @include media-up(lg) {
      top: 99%;
      left: 43%;
      transform: translate(-100%, -100%);
      width: 800px;
      height: 800px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: -16%;
    left: 62%;

    transform: translate(0%, -147%);
    width: 1000px;
    height: 1000px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(157, 220, 255, 0.3) 0%, rgba(157, 220, 255, 0) 100%);
    // background: radial-gradient(
    //   50% 50% at 50% 50%,
    //   rgba(0, 217, 245, 0.24) 0%,
    //   rgba(0, 245, 160, 0.32) 0.01%,
    //   rgba(0, 245, 160, 0.2) 28.12%,
    //   rgba(0, 245, 160, 0) 100%,
    //   rgba(0, 245, 160, 0) 100%
    // );
    transform: matrix(-1, 0, 0, 1, 0, 0);
    pointer-events: none;

    display: none;
    @include media-up(lg) {
      top: -16%;
      left: 62%;
      transform: translate(-23%, -24%);
      opacity: 0.9;
      display: block;
    }
  }
  .ant-empty-description {
    color: #003e9d;
  }
}
