@import 'assets/css/variables.scss';

.wrapper-form-verify {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-height: 400px) {
    align-items: center;
  }
}
