@import 'assets/css/variables.scss';

.wallet-box-recent-transaction {
  margin-top: 24px;
  @include media-down(sm) {
    margin-top: 8px;
  }
  .ant-table-container {
    padding: 16px 32px;
    background: #ffffff;
    border-radius: 12px 12px 0 0;
  }
  .ant-table-scroll-horizontal {
    position: relative;
    &::after {
      content: '';
      //position: absolute;
      background-image: none;
      //background-image: linear-gradient(to top, rgba(255, 0, 0, 0), black);
      transform: matrix(1, 0, 0, -1, 0, 0);
      height: 30px;
      width: 100%;
      bottom: 0px;
      left: 0;
      z-index: 1;
    }
  }
}

.table-wallet-history {
  .ant-table {
    background: transparent;
  }

  /* Scrollbar */
  // .ant-table-container {
  //   overflow: auto !important;
  //   &::-webkit-scrollbar {
  //     width: 5px;
  //     height: 5px;
  //     background: none;
  //     border-radius: 3px;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     width: 3px;
  //     background: #003e9d !important;
  //   }
  //   &::-webkit-scrollbar-track {
  //     width: 3px;
  //     background: rgba(190, 190, 190, 0.4) !important;
  //   }
  // }
  // .ant-table-body {
  //   overflow: auto !important;
  //   &::-webkit-scrollbar {
  //     width: 5px;
  //     height: 5px;
  //     background: none;
  //     border-radius: 3px;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     width: 3px;
  //     background: #003e9d !important;
  //   }
  //   &::-webkit-scrollbar-track {
  //     width: 3px;
  //     background: rgba(190, 190, 190, 0.4) !important;
  //   }
  //   &::-webkit-scrollbar:horizontal {
  //     height: 5px;
  //     width: 5px;

  //     //display: none;
  //   }
  //   &::-webkit-scrollbar-thumb:horizontal {
  //     //display: none;
  //     background: #003e9d !important;
  //   }
  // }

  thead.ant-table-thead {
    th.ant-table-cell {
      // font-size: 16px;
      // line-height: 150%;
      // color: #ffffff;
      white-space: nowrap;
      background: transparent;
      border: unset;

      font-weight: 600;
      font-size: 20px;
      line-height: 27px;

      text-align: center;
      // text-decoration-line: underline;

      color: #111111;

      &:first-child {
        text-align: left;
        padding-left: 0;
      }
      &:not(:first-child) {
        text-align: center;
      }
    }
  }

  .ant-table-tbody {
    tr.ant-table-row:hover > td {
      background: transparent;
      border: unset;
    }
    td.ant-table-cell {
      //color: #ffffff;
      white-space: nowrap;
      border: unset;

      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #0c0c0c;
      text-align: center;

      &:first-child {
        padding-left: 0;
      }
      .recent-transaction-table-status {
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        width: 100px;
        height: 29px;

        border-radius: 8px;

        &[data-status='CREATED'] {
          color: #ef8087;
        }
        &[data-status='COMPLETED'] {
          color: #ffffff;
          background: #27b38c;
        }
      }
      .recent-transaction-table-amount {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        color: #003682;
        white-space: nowrap;
        background: transparent;

        display: flex;
        justify-content: center;

        &:not(:first-child) {
          text-align: center;
        }

        .coin-data {
          margin-left: 4px;
        }
      }
      .recent-transaction-table-network {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        color: #0c0c0c;
      }
      .recent-transaction-table-time {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        color: #0c0c0c;
      }
      .recent-transaction-table-view {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        color: #003e9d;
      }
    }
    tr.ant-table-placeholder:hover > td {
      background: transparent;
      border: unset;
    }
  }
  .ant-table-cell-scrollbar {
    //display: none;
  }
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}
.ant-table-container {
  overflow: auto !important;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: none;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background: #003e9d !important;
  }
  &::-webkit-scrollbar-track {
    width: 3px;
    background: rgba(190, 190, 190, 0.4) !important;
  }
}
