@import 'assets/css/variables.scss';

.find-wrapper {
  padding: 32px;
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 20px;
  margin-top: 32px;
  @include media-down(md) {
    padding: 24px;
  }
  @include media-down(sm) {
    padding: 0;
    background: none;
    border: none;
  }
  // @include media-down(xs) {
  //   padding: 16px 8px;
  // }
  .find-top {
    display: flex;
    margin-bottom: 48px;
    gap: 65px;
    @include media-down(1300px) {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 32px;
    }
    @include media-down(sm) {
      margin-bottom: 14px;
      gap: 6px;
    }
    .find-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 43px;
      color: #003e9d;
      @include media-down(1300px) {
        font-size: 28px;
      }
      @include media-down(sm) {
        font-size: 14px;
        line-height: 19px;
      }
    }
    .find-input {
      width: 65%;
      @include media-down(1300px) {
        width: 100%;
      }
      .ant-input {
        background: #f2faff;
        border: 1px solid #003e9d;
        border-radius: 8px;
        height: 44px;
        font-size: 20px;
        color: #003e9d;
        &::placeholder {
          color: rgba(1, 54, 130, 0.5);
        }
        @include media-down(sm) {
          font-size: 14px;
          color: #003e9d;
        }
      }
    }
  }
  .find-tbl {
    .ant-table {
      border-radius: 8px;
      background: #f2faff;
    }
    .find-tbl-header {
      padding: 8px;
      text-align: center;
    }
    .find-tbl-number {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #003e9d;
      text-align: center;
    }
    .find-tbl-address {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #003e9d;
      text-align: center;
    }
    .find-tbl-check {
      text-align: center;
      .ant-checkbox-inner {
        width: 16px;
        height: 16px;

        background: #002072;
        border-radius: 4px;
      }
      .ant-checkbox + span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #003e9d;
      }
    }
    .find-tbl-remove {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #003e9d;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    .ant-table-thead > tr > th {
      background: #003e9d;
      font-weight: 700;
      font-size: 20px;
      line-height: 16px;
      color: #dfecff;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      border-top-left-radius: 8px;
    }
    .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 8px;
    }
  }
  .find-list {
    .ant-list-split .ant-list-item {
      border-bottom: none;
    }
    .list-addresses {
      background: #dfecff;
      width: 100%;
      border-radius: 12px;
      padding: 8px 16px;
      @include media-down(xs) {
        padding: 8px;
      }
      .ant-list-split .ant-list-item {
        border-bottom: none;
      }
      .list-addresses-row:last-child {
        margin-bottom: 0;
      }
      .list-addresses-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        align-items: center;
        .list-addresses-title {
          p {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #003e9d;
            @include media-down(md) {
              font-size: 18px;
            }
            @include media-down(md) {
              font-size: 14px;
            }
          }
        }
        .list-addresses-value {
          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #003e9d;
            @include media-down(md) {
              font-size: 18px;
            }
            @include media-down(md) {
              font-size: 14px;
            }
          }
          .btn-remove {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #003e9d;
            @include media-down(md) {
              font-size: 18px;
            }
            @include media-down(md) {
              font-size: 14px;
            }
          }
          .find-tbl-check {
            text-align: center;
            .ant-checkbox-wrapper {
              @include media-down(xs) {
                align-items: flex-start;
              }
            }
            .ant-checkbox-inner {
              width: 16px;
              height: 16px;

              background: #002072;
              border-radius: 4px;
              @include media-down(xs) {
                width: 12px;
                height: 12px;
              }
            }
            .ant-checkbox + span {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: #003e9d;
              padding-right: 0;
              @include media-down(xs) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .ant-empty-description {
    color: #003e9d;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }
}
