@import 'assets/css/variables.scss';

.ant-modal.custom-modal.modal-confirm-package {
  //top: 20%;
  .modal-confirm-img {
    margin-top: 54px;
    display: flex;
    justify-content: center;
    img {
      width: 300px;
      height: 300px;
      @include media-down(sm) {
        width: 124px;
        height: 124px;
      }
    }
  }
  .modal-confirm-btn {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 54px;
  }
}
