@import 'assets/css/variables.scss';

.ant-modal.custom-modal.modal-2fa {
  width: 552px !important;
}
.modal-2fa {
  max-width: 100vw;
  margin-bottom: 70px;
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-footer {
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 49px;
      @include media-down(md) {
        padding-bottom: 32px;
      }
      .button-primary {
        width: 150px;
        @include media-down(sm) {
          width: 110px;
        }
        &:first-child {
          border: 1px solid #003e9d;
          border-radius: 8px;
          background: #ffffff;
          color: #003e9d;
        }
        &:last-child {
          border: 1px solid #003e9d;
          background: #003e9d;
          border-radius: 8px;
          color: #ffffff;
        }
      }
    }
    .ant-modal-header {
      padding: 24px;
      padding: 52px 24px 0;

      .modal-verify-cody-header {
        font-weight: 700;
        font-size: 32px;
        line-height: 43px;
        color: #003e9d;
      }
    }
    .ant-modal-body {
      text-align: center;
      padding: 15px 45px;
      @include media-down(sm) {
        padding: 32px 8px;
      }
    }
    // .ant-modal-footer {
    //   padding-bottom: 30px;
    // }
  }
  .powered-by {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #777e91;
    text-align: center;
    > span {
      color: #003e9d;
    }
  }
  .canvas-qrcode {
    width: 100%;
    height: 100%;
    max-width: 204px;
    max-height: 204px;
    margin: 0 auto 20px;
    @include media-down(sm) {
      margin: 0 auto 16px;
    }

    // svg {
    //   width: 200px;
    //   height: 200px;
    // }
  }
  .input-code-container > div .input-code-item input {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #003e9d;
  }

  .text-note {
    //color: #fff;
    font-weight: 400;
    //font-size: 14px;
    line-height: 24px;
    padding: 20px 0;
    //max-width: 300px;
    margin: 0 auto;
    // font-weight: 400;
    // font-size: 16px;
    // color: #003e9d;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #003e9d;
      @include media-down(sm) {
        font-size: 12px;
      }
      > span {
        font-weight: 700;
      }
    }
  }
}
