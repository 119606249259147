/* Variables */
/* Animation */
@-webkit-keyframes ssc-loading {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes ssc-loading {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
/* Animation partial */
.ssc-square,
.ssc-head-line,
.ssc-line,
.ssc-circle {
  position: relative;
  overflow: hidden;
}
.ssc-square:after,
.ssc-head-line:after,
.ssc-line:after,
.ssc-circle:after {
  content: '';
  -webkit-animation: ssc-loading 1.3s infinite;
  animation: ssc-loading 1.3s infinite;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.3)),
    to(transparent)
  );
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
}

/** Main class */
.ssc {
  cursor: progress;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ssc-square {
  display: block;
  background-color: #b0aeae;
  width: 100%;
  border-radius: 10px;
}

/*# sourceMappingURL=/index.css.map */
