@import 'assets/css/variables.scss';

.private-footer {
  width: 100%;
  min-height: 70px;

  display: none;
  @include media-up(lg) {
    display: block;
  }

  ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    width: 100%;
    list-style: none;
    margin: 0;

    li {
      padding: 33.33px;

      a {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #003e9d;

        &:hover {
          color: #2b6fcd;
        }
      }
    }
  }
}
