@import 'assets/css/variables.scss';
.block-content {
  flex: 1;
  //height: 195px;
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  box-shadow: 0px 4px 10px rgba(23, 105, 201, 0.25);
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  @include media-between(sm, md) {
    width: 100%;
  }
  @include media-down(sm) {
    gap: 8px;
  }
  &_img img {
    margin-top: 33px;
    width: 52.5px;
    height: 52.5px;
    @include media-between(sm, md) {
      width: 48.5px;
      height: 48.5px;
    }
    @include media-down(sm) {
      margin-top: 19px;
      width: 42px;
      height: 42px;
    }
  }
  &_text {
    span {
      font-weight: 700;
      font-size: 28px;
      line-height: 140%;
      /* or 39px */
      text-align: center;
      color: #003e9d;
      @include media-between(sm, md) {
        font-size: 25px;
      }
      @include media-down(sm) {
        font-size: 24px;
      }
    }
  }
  &_desc {
    white-space: nowrap;
    display: inline-block;
    margin-bottom: 24px;
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      /* or 22px */
      text-align: center;
      color: #003e9d;
      @include media-between(sm, md) {
        font-size: 16px;
      }
      @include media-down(sm) {
        font-size: 12px;
        line-height: 140%;
      }
    }
    @include media-down(sm) {
      font-size: 12px;
      margin-bottom: 12px;
    }
  }
  .clr-blue {
    color: #003e9d;
  }
  .clr-gray {
    color: #808080;
  }
}
