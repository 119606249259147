.text-center {
  text-align: center;
}

.mobile-hidden {
  @include media-down(sm) {
    display: none;
  }
}

@include media-up(sm) {
  .desktop-hidden {
    display: none;
  }
}
