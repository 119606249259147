@import 'assets/css/variables.scss';

.addresses-wrapper {
  padding: 32px;
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 20px;
  @include media-down(md) {
    padding: 24px;
  }
  @include media-down(sm) {
    padding: 0;
    background: none;
    border: none;
  }
  // @include media-down(xs) {
  //   padding: 16px 8px;
  // }

  .ant-form-item {
    margin-bottom: 0;
  }
  .addresses-top {
    display: flex;
    gap: 32px;
    margin-bottom: 64px;
    @include media-down(md) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;
    }
    @include media-down(sm) {
      gap: 16px;
    }
    &_img {
      img {
        object-fit: cover;
        width: 100px;
        height: 100px;
        @include media-down(1300px) {
          width: 90px;
          height: 90px;
        }
      }
    }
    &_content {
      @include media-down(md) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      p {
        font-weight: 700;
        font-size: 32px;
        line-height: 43px;
        color: #003e9d;
        @include media-down(1300px) {
          font-size: 30px;
        }
        @include media-down(md) {
          margin-bottom: 16px;
        }
        @include media-down(sm) {
          font-size: 20px;
          line-height: 27px;
        }
      }
      &_mobile {
        @include media-down(md) {
          display: flex;
          align-items: center;
          gap: 12px;
        }
        p.line {
          width: 2px;
          height: 15px;

          background: rgba(1, 54, 130, 0.2);
          border-radius: 10px;
          display: none;
          @include media-down(md) {
            display: block;
          }
        }
        p {
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          color: #003e9d;
          span {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: #003e9d;
            @include media-down(1300px) {
              font-size: 18px;
            }
            @include media-down(sm) {
              font-size: 14px;
              line-height: 19px;
            }
            @include media-down(xs) {
              display: flex;
              justify-content: center;
            }
          }
          @include media-down(1300px) {
            font-size: 18px;
          }
          @include media-down(sm) {
            font-size: 14px;
            line-height: 19px;
          }
          @include media-down(xs) {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .addresses-title {
    margin-bottom: 48px;
    @include media-down(md) {
      margin-bottom: 16px;
    }
    .back-link .back-icon {
      @include media-down(sm) {
        margin-right: 24px;
      }
    }
    .back-icon {
      img {
        width: 14px;
        height: 28px;
        @include media-down(sm) {
          width: 12px;
          height: 16px;
        }
      }
    }
    .back-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 64px;
      color: #003e9d;
      @include media-down(1300px) {
        font-size: 44px;
      }
      @include media-down(md) {
        font-size: 32px;
      }
      @include media-down(sm) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
  .addresses-sync {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    @include media-down(md) {
      margin-bottom: 16px;
      flex-direction: column;
      align-items: start;
    }
    .sync-title {
      flex: 20%;
      @include media-down(1300px) {
        margin-right: 16px;
      }
      @include media-down(md) {
        margin-bottom: 8px;
      }
      span {
        font-weight: 500;
        font-size: 32px;
        line-height: 43px;
        color: #003e9d;
        @include media-down(1300px) {
          font-size: 28px;
        }
        @include media-down(sm) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    .sync-input {
      flex: 60%;
      @include media-down(md) {
        width: 100%;
        margin-bottom: 24px;
      }
      .ant-input {
        background: #f2faff;
        border: 1px solid #003e9d;
        border-radius: 8px;
        height: 44px;
        font-size: 20px;
        &::placeholder {
          color: rgba(1, 54, 130, 0.5);
        }
        @include media-down(sm) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    .sync-btn {
      margin-left: 50px;
      @include media-down(1300px) {
        margin-left: 18px;
      }
      @include media-down(md) {
        margin: 0 auto;
        text-align: center;
      }
      flex: 20%;
      button {
        width: 100%;
        height: 48px;
        max-width: 150px;
        min-width: 110px;
        @include media-down(md) {
          height: 40px;
        }
      }
      .button-secondary div {
        background: #003e9d;
        border-radius: 8px;
      }
      .button-secondary div span {
        background: #ffffff;
        -webkit-background-clip: text;
      }
    }
  }
  .adresses-trans-watcher {
    display: flex;
    align-items: center;
    gap: 80px;
    @include media-down(1300px) {
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
    }
    @include media-down(md) {
      gap: 8px;
    }
    .trans-watcher-title {
      span {
        font-weight: 500;
        font-size: 32px;
        line-height: 43px;
        color: #003e9d;
        @include media-down(1300px) {
          font-size: 28px;
        }
        @include media-down(sm) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    .ant-checkbox-inner {
      width: 32px;
      height: 32px;

      background: #002072;
      border-radius: 4px;
      @include media-down(sm) {
        width: 24px;
        height: 24px;
      }
    }
    .ant-checkbox-inner::after {
      left: 32%;
      width: 7px;
      height: 12px;
      background: none;
    }
    .trans-watcher-checked {
      display: flex;
      gap: 16px;
      position: relative;
      width: auto;
      justify-content: center;

      @include media-down(md) {
        width: 100%;
      }
      .ant-checkbox-wrapper {
        @include media-down(sm) {
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }
      }
      .ant-checkbox-group .ant-checkbox-wrapper:first-child {
        @include media-down(md) {
          position: absolute;
          left: 0;
        }
      }
      .checked-both .ant-checkbox-wrapper {
        @include media-down(md) {
          position: absolute;
          right: 0;
        }
      }
      .ant-checkbox + span {
        font-weight: 500;
        font-size: 32px;
        line-height: 43px;
        color: #003e9d;
        padding-right: 0;
        @include media-down(1300px) {
          font-size: 28px;
        }
        @include media-down(sm) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}
