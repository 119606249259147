@import 'assets/css/variables.scss';

.ant-modal-wrap {
  @include media-down(sm) {
    height: 100vh;
  }
}
.custom-modal {
  // top: 30px;
  @include media-down(sm) {
    padding: 16px;
  }
  @include media-down(xm) {
    padding: 8px;
  }
  .ant-modal-header {
    @include media-down(xm) {
      padding: 16px 8px;
    }
  }
  .ant-modal-body {
    @include media-down(sm) {
      padding: 16px 0;
    }
  }
  .ant-modal-close {
    @include media-down(sm) {
      right: 8px;
    }
  }
}

.custom-modal .ant-modal-content {
  background: #ffffff;
  border: 1px solid #003e9d;
  border-radius: 12px;
}
.custom-modal .ant-modal-header .ant-modal-title {
  margin-top: 4px;
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  color: #003e9d;
  @include media-down(sm) {
    font-size: 16px;
  }
}
.ant-modal-body {
  .input-code-container > div .input-code-item {
    @include media-down(sm) {
      height: 50px;
    }
  }
  .input-code-container > div .input-code-item input {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #003e9d;
    @include media-down(sm) {
      font-size: 18px;
    }
  }
  .modal-delete-title {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    color: #003e9d;
    margin-bottom: 24px;
    @include media-down(sm) {
      font-size: 24px;
      margin-bottom: 32px;
    }
  }
  .modal-delete-text {
    display: flex;
    justify-content: center;

    margin: 48px 0 24px 0;
    color: #ff4343;
    text-align: center;

    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    color: #fd5353;
    @include media-down(sm) {
      font-size: 16px;
      margin: 8px 0 12px 0;
    }
  }
  // p {
  //   display: flex;
  //   justify-content: center;
  //   font-weight: 700;
  //   font-size: 32px;
  //   line-height: 43px;
  //   margin: 48px 0 24px 0;
  //   color: #FF4343;
  //   @include media-down(sm) {
  //     font-size: 16px;
  //     margin: 8px 0 12px 0;
  //   }
  // }
  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #003e9d;
    @include media-down(sm) {
      font-size: 12px;
    }
  }
  .verify-title-delete {
    font-weight: 800;
    padding: 0 57px;
    height: 32px;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    color: #ff4343;

    @include media-down(sm) {
      font-size: 16px;
      padding: 0 22px;
    }
  }
  .verify-resend {
    margin-top: 18px;
    margin-bottom: 33px;
    display: flex;
    justify-content: space-between;
    > div {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #003e9d;
      @include media-down(sm) {
        font-size: 12px;
      }
    }
  }
  .generate-confirm {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    .button-primary {
      width: 150px;
      @include media-down(sm) {
        width: 110px;
      }
    }
  }
}
