@import 'assets/css/variables.scss';

.project-content {
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 12px;
  display: flex;
  padding: 24px 32px;
  gap: 32px;
  .project-right {
    background: #003e9d;
    border-radius: 12px;
    @include media-down(700px) {
      width: 61px;
      height: 61px;
    }
    .project-content_img {
      width: 93px;
      height: 93px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-down(700px) {
        width: 61px;
        height: 61px;
      }
      img {
        width: 80px;
        height: 80px;
        @include media-down(700px) {
          width: 51px;
          height: 51px;
        }
      }
    }
  }
  .project-left {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .project-button {
      .ant-btn {
        width: 143px;
        height: 48px;
        background: #27b38c;
        box-shadow: 0px 4px 10px rgba(23, 105, 201, 0.25);
        border-radius: 12px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-down(md) {
          width: 120px;
        }
        @include media-down(700px) {
          width: 79px;
          height: 32px;
        }
        @include media-down(sm) {
          border-radius: 6px;
        }
        @include media-down(410px) {
          width: 65px;
        }
      }
      span {
        color: #ffffff;
      }
    }
    &_info {
      display: flex;
      flex-direction: column;
      &_title {
        span {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          color: #003e9d;
        }
      }
      &_value {
        span {
          font-weight: 700;
          font-size: 32px;
          line-height: 43px;
          color: #003e9d;
        }
      }
      &_desc {
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          color: #003e9d;
        }
      }
    }
  }
}
