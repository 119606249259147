@import 'assets/css/variables.scss';

.warp-exp {
  position: relative;
  max-width: 1070px;
  margin: 382px auto 280px;
  // overflow: hidden;
  @include media-down(lg) {
    margin: 0px 24px 200px;
  }
  .ant_row {
    display: flex;
    align-items: center;
    @include media-down(xl) {
      flex-direction: column-reverse;
    }
    .col-left {
      max-width: 330px;
      margin-right: 110px;
      @include media-down(xl) {
        max-width: 640px;
        width: 100%;
        margin: 24px auto 0px;
      }
      h2 {
        margin-bottom: 32px;
        margin-right: 88px;
        font-weight: 700;
        font-size: 24px;
        line-height: 33.6px;
        color: #000000;
        @include media-down(sm) {
          font-size: 16px;
          margin-bottom: 24px;
        }
      }
      ul li {
        font-size: 16px;
        margin-left: 22px;
        color: #000000;
        @include media-down(sm) {
          font-size: 14px;
        }
      }
    }
    .col-right {
      max-width: 640px;
      img {
        width: 100%;
      }
    }
  }
}
