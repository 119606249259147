@import 'assets/css/variables.scss';

.manage-package-wrapper {
  margin-top: 32px;
  width: 100%;
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 20px;
  padding: 32px;
  @include media-down(md) {
    padding: 24px;
  }
  @include media-down(sm) {
    padding: 24px 16px;
    border-radius: 12px;
  }
  @include media-down(330px) {
    padding: 16px 8px !important;
  }

  .row-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #003e9d;
    @include media-down(sm) {
      font-size: 14px;
      line-height: 19px;
    }
  }
  .row-content {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    white-space: nowrap;
    color: #003e9d;
    @include media-down(xl) {
      font-size: 22px;
    }
    @include media-down(sm) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &_title {
    margin-bottom: 64px;
    @include media-down(md) {
      margin-bottom: 32px;
    }
    span {
      font-weight: 700;
      font-size: 32px;
      line-height: 43px;
      color: #003e9d;
      @include media-down(md) {
        font-size: 28px;
        line-height: 21px;
      }
      @include media-down(sm) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
  &_name {
    margin-bottom: 48px;
    display: flex;
    gap: 48px;
    @include media-down(md) {
      margin-bottom: 32px;
    }
    @include media-down(sm) {
      gap: 35px;
    }
  }
  &_duration {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include media-down(md) {
      flex-direction: column;
      gap: 32px;
    }
    .duration-left {
      display: flex;
      flex-direction: column;
      gap: 48px;
      width: 55%;
      @include media-down(md) {
        width: 100%;
      }
      @include media-down(sm) {
        gap: 32px;
      }
      &_top {
        display: flex;
        gap: 18px;
      }
      @include media-down(xl) {
        margin-right: 10px;
      }
      &_bottom {
        ul {
          margin-left: 100px;
          @include media-down(xl) {
            margin-left: 0;
          }
          @include media-down(md) {
            margin-left: 120px;
          }
          @include media-down(sm) {
            margin-left: 77px;
          }
          @include media-down(xm) {
            margin-left: 0;
          }
          li {
            margin-bottom: 24px;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #003e9d;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include media-down(xl) {
              font-size: 20px;
            }
            @include media-down(md) {
              font-size: 18px;
            }
            @include media-down(sm) {
              font-size: 14px;
              line-height: 19px;
            }
            span {
              width: 22px;
              height: 22px;
              background: #003e9d;
              border-radius: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          li:last-child {
            margin: 0;
          }
        }
      }
    }
    .duration-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      justify-content: space-around;
      @include media-down(xl) {
      }
      img {
        width: 248px;
        height: 248px;
        margin-top: 6px;
        @include media-down(xl) {
          width: 210px;
          height: 220px;
        }
        @include media-down(md) {
          width: 170px;
          height: 170px;
        }

        @include media-down(sm) {
          width: 124px;
          height: 124px;
        }
      }

      &_button {
        width: 146px;
        height: 45px;
        background: #003e9d;
        border-radius: 8px;
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        @include media-down(sm) {
          width: 184px;
          height: 45px;

          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }
}
