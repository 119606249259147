@import 'assets/css/variables.scss';

.footer-container {
  background-color: #25282d;
  overflow: hidden;

  .footer-content {
    // width: 100%;
    max-width: 630px;
    text-align: center;
    margin: 70px auto;

    @include media-down(sm) {
      max-width: 266px;
      margin: 48px auto;
    }
    .footer-logo {
      // margin-right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        // max-width: 280px;
        object-fit: contain;
        // margin-right: 10px;
      }
      span {
        color: #ffffff;

        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        white-space: nowrap;
      }
    }
    .socials-list {
      margin: 48px 0px;
      @include media-down(sm) {
        margin: 24px 0px;
      }

      a {
        display: inline-block;
        margin: 0 16px;
      }
      img {
        width: 36px;
        // height: 20px;
        // object-fit: contain;

        @include media-down(sm) {
          width: 24px;
        }
      }
    }
    .footer-bottom {
      p {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        @include media-down(sm) {
          font-size: 8px;
        }
      }
    }
  }
}
