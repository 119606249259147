@import 'assets/css/variables.scss';
.dashboard-project-container {
  height: 147px;
  width: 100%;
  background-color: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  //padding: 24px 32px;
  align-items: center;
  @include media-down(sm) {
    //padding: 16px;
    height: 93px;
  }
  .proj-left {
    display: flex;
    gap: 32px;
    @include media-down(sm) {
      gap: 16px;
    }
    @include media-down(408px) {
      gap: 8px;
    }
    .proj-img {
      img {
        margin: 24px 0 30px 32px;
        width: 93px;
        height: 93px;
        @include media-down(sm) {
          width: 61px;
          height: 61px;
          margin: 16px 0 16px 16px;
        }
        @include media-down(408px) {
          margin: 16px 0 16px 8px;
        }
        @include media-down(xm) {
          width: 55px;
          height: 55px;
        }
      }
    }
    .proj-info {
      margin: 24px 0 24px 0;
      gap: 4px;
      display: flex;
      flex-direction: column;
      @include media-down(sm) {
        margin: 16px 16px 16px 0;
      }
      @include media-down(408px) {
        margin: 16px 0 16px 0;
      }
      &_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        /* identical to box height */
        color: #002a8b;
        @include media-down(sm) {
          font-size: 12px;
          line-height: 16px;
        }
      }
      &_text {
        font-weight: 700;
        font-size: 32px;
        line-height: 43px;
        /* identical to box height */
        color: #002a8b;
        @include media-down(sm) {
          font-size: 16px;
          line-height: 21px;
        }
      }
      &_desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #002a8b;
        white-space: nowrap;
        @include media-down(sm) {
          font-size: 12px;
          line-height: 16px;
        }
        @include media-down(xm) {
          font-size: 10px;
        }
      }
    }
  }
  .proj-right {
    margin: 49.5px 32px 49.5px 0;
    @include media-down(sm) {
      margin: 16px 16px 16px 0;
    }
    @include media-down(408px) {
      margin: 16px 8px 16px 0;
    }
    .ant-btn {
      width: 143px;
      height: 48px;
      background: #27b38c;
      box-shadow: 0px 4px 10px rgb(23 105 201 / 25%);
      border-radius: 12px;
      padding: 8px;
      border: none;
      @include media-down(sm) {
        border-radius: 6px;
        width: 79px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @include media-down(xm) {
        width: 72px;
      }
    }
  }
}
