@import 'assets/css/variables.scss';

.warp-partner {
  max-width: 1290px;
  margin: -260px auto 382px;

  @include media-down(xl) {
    padding: 0 24px;
  }
  @include media-down(md) {
    margin-top: 0px;
    margin-bottom: 100px;
  }
  .warp-bg {
    // position: relative;
    padding-top: 48px;
    padding-bottom: 48px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    // height: 230px;
    border-radius: 30px;
    @include media-down(md) {
      display: none;
    }
    .bg_data {
      position: relative;
      padding: 50px 109px;
      background: #fff;
      box-shadow: 0px 10px #d5d5d5;
      border-radius: 30px;
      max-width: 850px;
      height: 284px;
      margin: 0px auto;
      .ant-carousel {
        height: 100%;
        .slick-slider {
          height: 100%;
          img.slick-arrow.slick-prev {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 10rem;
            left: -10rem;
          }
          img.slick-arrow.slick-next {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 10rem;
            right: -10rem;
          }
          .slick-list {
            height: 100%;
            position: relative;
            /* display: inline; */
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
        }
      }
      img {
        width: 123px;
      }
      .slick-slide.slick-active.slick-current {
        // position: absolute;
        // height: 100%;
        display: flex;
        justify-content: space-between;
        h1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 140%;
          margin-bottom: 8px;
          text-align: center;
        }
        // img {
        //   width: 123px;
        // }
      }
    }
  }
  //mobile
  .warp-bg-mobile {
    padding: 32px 25px;
    // background-size: 380px 172px;
    background-size: 580px 172px;
    background-repeat: no-repeat;
    // max-width: 380px;
    max-width: 580px;
    margin: 0 auto;
    height: 172px;
    border-radius: 8px;
    display: none;
    @include media-down(md) {
      display: block;
    }
    .ant-carousel {
      height: 100%;
      .slick-slider {
        height: 100%;
        img.slick-arrow.slick-prev {
          position: absolute;
          width: 10px;
          height: 10px;
          top: 60px;
          left: -22px;
        }
        img.slick-arrow.slick-next {
          position: absolute;
          width: 10px;
          height: 10px;
          top: 60px;
          right: -22px;
        }
        .slick-list {
          height: 100%;
          position: relative;
          /* display: inline; */
          margin: 0;
          padding: 0;
          overflow: hidden;
        }
        .slick-slide.slick-active.slick-current {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .bg_items {
            display: flex !important;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 8px;
            width: 86px !important;
            height: 107px;
            background-color: rgba(255, 255, 255);
            border-radius: 8px;
            @include media-down(xm) {
              padding: 12px 8px;
              gap: 16px;
              width: 70px !important;
            }
            h1 {
              margin: 0;
              font-size: 12px;
              font-weight: 700;
              @include media-down(xm) {
                font-size: 10px;
              }
            }

            img {
              // width: 62px;
              @include media-down(md) {
                width: 62px;
              }
              @include media-down(xm) {
                width: 48px;
              }
            }
          }
        }
      }
    }
  }
}
