@import 'assets/css/variables.scss';

.secret-key-wrapper {
  padding: 32px;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 30px;
  margin-top: 32px;
  background: #ffffff;
  @include media-down(md) {
    padding: 24px;
  }
  @include media-down(sm) {
    padding: 24px 16px;
    border-radius: 12px;
  }
  @include media-down(330px) {
    padding: 16px 8px;
  }
  &_title {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    color: #003e9d;
    margin-bottom: 32px;
    @include media-down(md) {
      font-size: 28px;
    }
    @include media-down(sm) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    .secret-button {
      width: 50%;
      min-width: 330px;
      max-width: 410px;
      height: 54px;

      background: #003e9d;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      text-align: center;
      @include media-down(md) {
        font-size: 22px;
      }

      @include media-down(sm) {
        width: 100%;
        font-size: 16px;
        line-height: 140%;
        min-width: 10px;
      }
      color: #ffffff;
    }
  }

  &_key {
    display: flex;
    width: 100%;
    align-items: center;
    .secret-key-title {
      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        color: #003e9d;
        margin-right: 32px;
        @include media-down(md) {
          font-size: 22px;
        }
        @include media-down(sm) {
          font-size: 14px;
          line-height: 140%;
        }
      }
    }
    .secret-key-input {
      width: 100%;
      .ant-col {
        width: 100%;
      }
      .input-coppy-wrapper .wrapper-input {
        padding: 0;
      }
    }
    margin-bottom: 32px;
  }
  &_desc {
    .secret-desc {
      display: flex;
      gap: 18px;
      align-items: flex-start;
      @include media-down(sm) {
        flex-direction: column;
      }
      &_txt {
        display: flex;
        flex-direction: column;
        @include media-down(sm) {
          gap: 16px;
        }
        span {
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          color: #787878;
          @include media-down(sm) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
