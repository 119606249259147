@font-face {
  font-family: 'IvyMode';
  src: url('../fonts/IvyMode-Regular.ttf');
}

@font-face {
  font-family: 'Exo';
  src: url('../fonts/DMSans-Regular.ttf');
}

// @font-face {
//   font-family: 'VisbyRoundCF-Regular';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../fonts/Webfont/VisbyRoundCF-Regular.eot'); /*IE9 Compat Modes*/
//   src: local('VisbyRoundCF-Regular'), local('Regular'),
//     url('../fonts/Webfont/VisbyRoundCF-Regular.eot?#iefix') format('embedded-opentype') /*IE6-IE8*/,
//     url('../fonts/Webfont/VisbyRoundCF-Regular.woff2') format('woff2') /*Super Modern browsers*/,
//     url('../fonts/Webfont/VisbyRoundCF-Regular.woff') format('woff') /*Modern browsers*/;
// }
