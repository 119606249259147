/*
* Dropdown select
*/
.ant-select-dropdown {
  background-color: #ffffff;
  .ant-select-item {
    .ant-select-item-option-content {
      font-size: 16px;
      //padding: 6px 40px;
      img {
        width: 40px;
      }
    }
  }
  .ant-select-item-option-selected {
    background-color: #f5f5f5;
  }
}
