@import 'assets/css/variables.scss';

.private-header-mobile {
  display: flex;

  height: 80px;
  width: 100%;
  // max-width: 1000px;
  // padding: 0 16px;
  // margin: 0 auto;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  // display: flex;
  // flex-flow: row nowrap;
  // align-items: center;
  // justify-content: space-between;

  @include media-up(xl) {
    height: 100px;
    position: relative;
  }

  .header-left {
    .panel-logo {
      width: 155px;
      height: 27px;
      @include media-up(400px) {
        width: 185px;
        height: 32.27px;
      }
      @include media-up(md) {
        width: 264px;
        height: 46.05px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .header-go-back {
      svg {
        width: 16px;
        @include media-up(lg) {
          display: none;
        }
      }
      h2 {
        font-size: 24px;
        @include media-up(lg) {
          display: none;
        }
      }
    }
    .header-logo {
      cursor: pointer;
      img {
        width: 100%;
        max-width: 140px;
        object-fit: contain;
        // margin-right: 10px;
      }
    }
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 0;
      @include media-up(lg) {
        display: none;
      }
    }
  }
  .header-right {
    margin-top: 2px;
    display: flex;
    // flex-flow: row nowrap;
    //flex-wrap: wrap-reverse;
    justify-content: flex-end;
    align-items: center;
    .header-logo {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent;
        border: none;
      }
      .ant-select-arrow {
        display: none;
      }
      .ant-select {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #003e9d;
      }

      margin-left: 20px;
      cursor: pointer;
    }
    .background-cover-img {
      width: 48px;
      height: 48px;
      padding: 2px;
      border-radius: 100%;
      background: linear-gradient(155deg, #db242d 12.37%, #ffe5d6 87.63%);
      overflow: hidden;
      display: block;
      margin-right: 24px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media (min-width: 768px) {
        margin-right: 10px;
      }
      @include media-down(sm) {
        width: 18px;
        height: 18px;
      }
      @include media-down(xm) {
        margin-right: 4px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    .avatar-image {
      width: fit-content;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      cursor: pointer;

      & > div {
        width: 40px;
        height: 40px;
        padding: 2px;
        border-radius: 100%;
        background: linear-gradient(to left, rgba(0, 117, 254, 1) 0%, rgba(0, 194, 254, 1) 100%);
        overflow: hidden;
      }
      span:first-child {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 100%;
        background: #000;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        margin-right: 20px;
      }
      span:last-child {
        color: #fcfcfd;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        white-space: nowrap;
      }
    }
    .select-chain {
      height: 100%;
      width: 120px;
      margin-right: 22px;
      .ant-select-selector {
        background: transparent;
        border-radius: 10px;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-arrow {
        color: #fff;
      }
    }
  }
}

/*
* Menu content mobile
*/
.menu-content-mobile {
  position: fixed;
  // top: 80px;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 0px;
  background: $secondary;
  z-index: 10;
  transition: ease-in-out 0.4s;
  opacity: 0;
  overflow: auto;

  visibility: hidden;
  &[data-visible='true'] {
    visibility: unset;
    height: 100%;
    opacity: 1;
  }
  .collapse-icon {
    display: flex;
    align-items: center;
    transform: scale(-1);
    margin-top: 30px;
    margin-right: 20px;
    input#menu {
      display: none;
    }

    .icon {
      width: 34px;
      height: auto;
      padding: 0;
      display: block;
      cursor: pointer;
    }

    .icon .menu,
    .icon .menu::before,
    .icon .menu::after {
      background: #fff;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
    }
    .icon .menu::before {
      width: 28px;
    }
    .icon .menu {
      width: 24px;
    }
    .icon .menu::after {
      width: 34px;
    }

    .icon:hover .menu,
    .icon:hover .menu::before,
    .icon:hover .menu::after {
      background: #fff;
    }

    .icon .menu::before {
      top: -8px;
    }

    .icon .menu::after {
      top: 8px;
    }

    #menu:checked + .icon .menu {
      background: transparent;
    }

    #menu:checked + .icon .menu::before {
      width: 28px;
      transform: rotate(45deg);
    }

    #menu:checked + .icon .menu::after {
      width: 28px;
      transform: rotate(-45deg);
    }

    #menu:checked + .icon .menu::before,
    #menu:checked + .icon .menu::after {
      top: 0;
      transition: top ease 0.3s, transform ease 0.3s 0.3s;
    }
  }
  .menu-body {
    min-height: 500px;
  }

  /*
  * Menu mobile Container 
  */
  ul.menu-link {
    list-style: none;
    margin: 16px 0 0;
    flex-flow: column nowrap;

    li {
      white-space: nowrap;
      padding: 16px 30px;
      a {
        color: rgba(93, 101, 136, 1);

        font-weight: 600;
        font-size: 18px;
        line-height: 160%;

        width: 100%;
        display: block;
        align-items: center;
        text-align: center;
      }
    }
    li.active {
      position: relative;
      a {
        color: #003e9d;
      }
    }
  }

  .menu-actions-mobile {
    width: 100%;
    padding: 30px 24px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    .button-secondary,
    .button-primary {
      width: 100%;
    }
    a {
      width: 100%;
      max-width: 127px;
      display: block;
    }
    & > a:first-child {
      margin-right: 10px;
    }
    & > a:last-child {
      margin-left: 10px;
      .button-primary {
        color: #ffffff !important;
      }
    }
  }
}
