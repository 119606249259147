@import 'assets/css/variables.scss';

.modal-qrcode-deposit {
  position: relative;
  background: #1a3035;
  bottom: 0;
  height: unset;
  max-width: 100vw;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 0;
  margin: 50px auto;
  @media (min-width: 991px) {
    border-radius: 15px;
    max-width: unset;
  }
  .ant-modal-close {
    top: 25px;
    right: 25px;

    .ant-modal-close-x {
      //border: 2px solid #353945;
      box-sizing: border-box;
      border-radius: 48px;
      height: 30px;
      width: 30px;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        svg {
          width: 30px;
          height: 30px;
          @include media-down(sm) {
            width: 20px;
            height: 20px;
          }
          path {
            fill: #ff4343;
          }
        }
      }
    }
  }
  .ant-modal-content {
    @media (min-width: 991px) {
      border-radius: 12px;
    }
    .ant-modal-header {
      border-bottom: none;
      background: transparent;
      padding: 52px 52px 0;

      .ant-modal-title {
        font-weight: 700;
        //font-size: 16px;
        //line-height: 140%;
        //font-weight: 400;
        font-size: 32px;
        line-height: 27px;
        //color: #ffffff;
        color: #292929;
        text-align: center;

        @include media-down(sm) {
          font-size: 22px;
        }
      }

      @media (min-width: 991px) {
        display: block;
      }
    }

    .ant-modal-body {
      text-align: center;
      @media (min-width: 991px) {
        padding: 52px;
      }
      .modal-deposit-qs-code {
        width: 100%;
        height: 100%;
        max-width: 250px;
        max-height: 250px;
        margin: 0 auto;
        margin-bottom: 16px;
        border: 2px solid white;
        @media (min-width: 991px) {
          max-width: 300px;
          max-height: 300px;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #777e91;
        margin-bottom: 30px;
        &.title-modal {
          font-weight: 600;
          font-size: 24px;
          line-height: 130%;
          color: #ffffff;
          margin-bottom: 4px;
        }

        @media (min-width: 991px) {
          font-size: 16px;

          &.title-modal {
            display: none;
          }
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }

  .ant-modal {
    max-width: 100vw !important;
    width: 100%;
    // bottom: 0;
    top: unset;
    position: fixed;
    @media (min-width: 576px) {
      max-width: unset;
      position: unset;
    }
  }
}
