@import 'assets/css/variables.scss';

.request-withdraw-modal {
  max-width: 630px;
  transform: none;
  width: 100% !important;
  @include media-down(md) {
    max-width: 100%;
  }
  .modal-withdraw-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    color: #003e9d;
  }
  .request-withdraw {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .withdraw-desc {
      margin-bottom: 32px;
      h4 {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;

        color: #000000;
      }
    }
    .wrapper-input {
      height: 36px;
      width: 312px;
      padding: 8px;
      @include media-down(md) {
        max-width: 100%;
      }
    }
    .withdraw-avaiable-balance {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      span {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;

        color: #003e9d;
      }
      @include media-down(sm) {
        margin-bottom: 24px;
      }
    }
    .withdraw-paypal-email {
      @include media-down(md) {
        flex-direction: column;
      }
    }
    .withdraw-avaiable-balance,
    .withdraw-paypal-email {
      display: flex;
      justify-content: space-between;

      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: #003e9d;
      }
    }
    .withdraw-button {
      display: flex;
      justify-content: center;
      gap: 24px;
      .button-secondary:first-child {
        background: #003e9d;
      }
      .button-secondary:first-child div span {
        background: #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
