@import 'assets/css/variables.scss';
.total-balance {
  margin-right: 9px !important;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  // min-width: 320px;
  // margin-right: 23px;
  @include media-down(xm) {
    margin-right: 4px !important;
  }
  .hide-balance {
    display: flex;
    p.balance-txt {
      margin-top: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;

      color: #f3f8ff;
      @include media-down(sm) {
        display: none;
        margin-top: 7.5px;
        font-size: 10px;
        line-height: 13px;
      }
    }
  }
  & > div {
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    width: fit-content;
    height: 37px;
    padding: 0 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #003e9d;
    border-radius: 12px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid transparent;
      background: #003e9d;
      border-radius: 12px;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }

    & > span:first-child {
      margin-right: 10px;
      white-space: nowrap;
    }
    @include media-down(sm) {
      height: 22px;
      background: #003e9d;
      border-radius: 4px;
    }
  }

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    @include media-down(sm) {
      font-size: 10px;
      line-height: 13px;
    }
    background: #f3f8ff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  svg {
    width: 15px;
    path {
      &:first-child {
        fill: #f3f8ff;
      }
      &:nth-child(2) {
        fill: #f3f8ff;
      }
      &:last-child {
        fill: #f3f8ff;
      }
    }
  }
}
