@import 'assets/css/variables.scss';

.balance-card {
  flex: 1;
  padding: 32px 0;
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  box-shadow: 0px 4px 10px rgba(23, 105, 201, 0.25);
  @include media-down(md) {
    padding: 32px 0;
  }
  @include media-down(sm) {
    border: 0.4px solid rgba(1, 54, 130, 0.5);
    box-shadow: 0px 4px 9px rgba(23, 105, 201, 0.25);
    border-radius: 20px;
    gap: 12px;
  }
  img {
    width: 48px;
    height: 48px;
    &[data-status] {
      width: 30px;
      height: 48px;
    }
  }
  .balance-card-header {
    .balance-card-header-content {
      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;

        text-align: center;

        color: #003e9d;

        @include media-down(sm) {
          font-size: 16px;
        }
        @include media-down(xl) {
          font-size: 30px;
        }
      }
    }
  }
  .balance-card-currency {
    max-width: 250px;
    .balance-card-currency-content {
      padding: 0 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 700;
      font-size: 48px;
      line-height: 140%;
      text-align: center;

      color: #003e9d;

      @include media-down(xl) {
        font-size: 32px;
      }
      @include media-down(md) {
        font-size: 32px;
      }
    }
  }
}
