@import 'assets/css/variables.scss';

.input-code-container {
  width: 100%;
  & > div {
    width: 100%;
    flex-flow: row nowrap;
    .input-code-item {
      // width: 100%;
      // height: 65px;
      width: 59px;
      height: 79px;

      @media (min-width: 768px) {
        height: 69px;
      }
      @include media-down(sm) {
        width: 50px !important;
        height: 54px;
      }
      @include media-down(xm) {
        width: 41px !important;
        height: 50px;
      }
      input {
        color: #003e9d;

        font-weight: 700;
        font-size: 24px;
        line-height: 24px;

        // width: 100%;
        // height: 100%;
        width: 59px !important;
        height: 79px;
        @include media-down(sm) {
          width: 50px !important;
          height: 54px;
        }
        @include media-down(xm) {
          width: 41px !important;
          height: 50px;
        }
        flex: 1 1 auto;

        text-align: center;
        background: #f2faff;
        border: 0.5px solid #003e9d;
        border-radius: 5px;

        &:valid {
          outline: unset;
        }
        &:focus,
        &:hover {
          border: 1px solid #34384c;
          outline: unset;
        }

        &:not(:first-child) {
          margin-left: 8px;
          @include media-up(sm) {
            margin-left: 20px;
          }
        }

        &.error {
          border: 1px solid $warning;
        }
      }
    }
  }
}
