@import 'assets/css/variables.scss';

.wrapper-form-signup-v2 {
  width: 100%;
  height: 100%;
  max-width: 740px;
  margin: 0 auto;
  padding-top: 0;
  @include media-up(xs) {
    padding: 16px 8px;
  }
  @include media-up(xs) {
    padding: 24px 16px;
  }
  @include media-up(lg) {
    padding-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // .ant-form-item {
  //   margin-bottom: 0;
  // }
  .form-signup {
    width: 100%;
    max-width: unset;
    margin: 0 auto;
    padding: 10px 1px;
    .form-signup-container {
      .form-item-checkbox {
        padding: 2px 0 2px;
      }
      .password-checked {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .check-length {
          img {
            width: 24px;
            height: 4px;
          }
        }
      }
      .ant-form-item {
        margin-bottom: 16px;
      }
      .form-item-input label span {
        margin-bottom: 0;
      }
      .sign-up-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: #003e9d;
        display: none;
        @include media-up(sm) {
          display: block;
        }
      }
      padding: 0 24px 30px;
      @include media-up(xs) {
        padding: 24px 16px;
        background: #ffffff;
        border-radius: 4px;
      }
      @include media-up(lg) {
        padding: 32px;
      }
      .form-item-checkbox {
        text-align: center;
        .ant-checkbox-wrapper span:last-child {
          text-align: left;
          @include media-up(sm) {
            text-align: center;
          }
          font-size: 14px;
          color: #003e9d;
        }
      }
      .form-title,
      .form-title-country {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
      }
      .form-title-country {
        margin-bottom: 0;
      }
      .title-private {
        color: #00f4a3;
        font-weight: 400;
      }
      @include media-up(xl) {
        width: 740px;
        // max-width: 740px;
        height: auto;
        overflow: hidden;
      }
      .form-action {
        width: 100%;
        max-width: 110px;
        margin: 0 auto;

        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        @include media-up(sm) {
          max-width: 150px;
        }
      }

      .form-links {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include media-up(sm) {
          flex-direction: row;
        }
        p {
          text-align: center;
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          color: #003682;
          // @include media-up(md) {
          //   font-weight: 400;
          // font-size: 14px;
          // line-height: 19px;
          // color: #003682;
          // }

          @include media-up(sm) {
            font-size: 14px;
          }
        }
        a {
          text-decoration: underline;
          color: #003e9d;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          margin-left: 5px;
          // @include media-up(xm) {
          //   font-size: 14px;
          // }
          @include media-up(sm) {
            font-size: 16px;
          }

          &:hover {
            opacity: 0.7;
          }
          &:active {
            opacity: 1;
          }
        }
      }
      .ant-select-selector {
        height: 50px;
        padding-left: 11px;
      }
      .ant-input-affix-wrapper.ant-input-password {
        border: 1px solid #003e9d;
        border-radius: 5px;
        background: #f2faff;
        height: 50px;
        input {
          border: none;
          background: none;
          height: auto;
        }
      }
      input {
        height: 50px;
        background: #f2faff;
        border: 1px solid #003e9d;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #003e9d;
      }
      .ant-select-selection-search {
        margin-top: 10px;
      }
    }
  }
  .verify-code {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #53c3ff;
    border-radius: 3px;
  }
}
