@import 'assets/css/variables.scss';

.notification-manage {
  //width: 100%;
  padding: 32px;
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 12px;
  @include media-down(md) {
    background: none;
    border: none;
  }
  @include media-down(sm) {
    padding: 16px;
  }
  @include media-down(xs) {
    padding: 8px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  &_header {
    display: flex;
    align-items: center;
    margin-bottom: 64px;
    gap: 32px;
    @include media-down(md) {
      flex-direction: column;
      margin-bottom: 32px;
      gap: 16px;
    }
    img {
      object-fit: cover;
      width: 100px;
      height: 100px;
    }
    p {
      font-weight: 700;
      font-size: 32px;
      line-height: 43px;
      color: #003e9d;
      @include media-down(sm) {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
  &_content {
    margin-bottom: 46px;
    .telegram-save {
      margin-top: 24px;
      text-align: center;
      button {
        width: 100%;
        background: #003e9d;
        border-radius: 8px;
      }
      .button-secondary div span {
        background: #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    @include media-down(sm) {
      margin-bottom: 16px;
    }
    &_title {
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      @include media-down(md) {
        margin-bottom: 32px;
      }
      .back-title {
        font-weight: 700;
        font-size: 48px;
        line-height: 64px;
        color: #003e9d;
        @include media-down(xl) {
          font-size: 42px;
        }
        @include media-down(md) {
          font-size: 32px;
        }
        @include media-down(sm) {
          font-size: 16px;
          line-height: 21px;
        }
      }
      .back-icon {
        img {
          width: 14px;
          height: 28px;
          @include media-down(sm) {
            width: 12px;
            height: 16px;
          }
        }
      }
    }
    &_text {
      margin-top: 30px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #003e9d;
      @include media-down(sm) {
        margin-top: 16px;
        font-size: 14px;
        line-height: 19px;
      }
    }
    &_input {
      display: flex;
      width: 100%;
      align-items: center;
      &.telegram {
        margin-top: 32px;
      }
      @include media-down(md) {
        flex-direction: column;
        align-items: flex-start;
      }
      .notification-manage-title {
        max-width: 210px;
        width: 100%;
        font-weight: 500;
        font-size: 32px;
        line-height: 43px;
        color: #003e9d;
        margin-right: 45px;

        @include media-down(xl) {
          margin-right: 8px;
        }
        @include media-down(md) {
        }
        @include media-down(sm) {
          font-size: 14px;
          line-height: 19px;
        }
      }
      .notification-manage-input {
        margin-right: 48px;
        flex: 4;
        @include media-down(md) {
          width: 100%;
          margin-top: 8px;
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          position: absolute;
          top: 52px;
        }
        input {
          max-width: 460px;
          width: 100%;
          height: 51px;
          background: #f2faff;
          border: 1px solid #003e9d;
          border-radius: 8px;
          @include media-down(md) {
            max-width: 100%;
          }
          @include media-down(sm) {
            height: 43px;
          }
        }
        @include media-down(xl) {
          margin-right: 16px;
        }
      }
      .notification-manage-save {
        flex: 1.2;
        @include media-down(md) {
          margin-top: 24px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .button-secondary {
          width: 100%;
          max-width: 150px;
          min-width: 110px;
          @include media-down(sm) {
            width: 100px;
          }
        }
        button {
          width: 100%;
          background: #003e9d;
          border-radius: 8px;
        }
        .button-secondary div span {
          background: #ffffff;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  &_submit {
    display: flex;
    justify-content: center;
    .button-secondary {
      width: 60%;
      max-width: 499px;
      min-width: 300px;
      @include media-down(sm) {
        width: 100%;
        min-width: 0;
      }
    }
    button {
      width: 100%;

      height: 48px;
      background: #003e9d;
      border-radius: 8px;
    }
    .button-secondary div span {
      background: #ffffff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.modal-send-test-telegram {
  .noti-send-test {
    margin-bottom: 24px;

    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;

    color: #1d1d1d;
    @include media-down(sm) {
      font-size: 16px;
    }
  }
}
