@import 'assets/css/variables.scss';

.auth-card {
  width: 100%;
  padding: 24px 16px;
  // border-radius: 30px;
  z-index: 2;

  // @include media-between(xs, xm) {
  //   padding: 24px 16px;
  // }
  @include media-up(sm) {
    padding: 24px 60px;
  }
  @include media-up(lg) {
    // background: #ffffff;
    opacity: 0.6;
    backdrop-filter: blur(15px);
    // box-shadow: inset 0px 2px 5px rgba(111, 255, 238, 0.15);
  }

  @include media-up(lg) {
    padding: 120px 60px;
  }
}
//root modal
.verify-code {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 674px !important;
  height: 284px;
  @include media-up(sm) {
    height: 380px;
  }
  @include media-up(lg) {
    height: 620px;
  }
  .verify-title {
    @include media-up(md) {
      font-weight: 700 !important;
      font-size: 32px !important;
      line-height: 43px !important;
      text-align: center !important;
      color: #003e9d !important;
    }
  }
}
