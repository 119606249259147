@import 'assets/css/variables.scss';

.realTime-section {
  padding: 32px 29px;
  background-color: #ffffff;
  border-radius: 30px;
  text-align: center;
  max-width: 720px;
  margin: 0 auto;
  @include media-up(lg) {
    width: 410px;
    text-align: unset;
    max-width: unset;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
  // @include media-between(xs, sm) {
  // }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 33.6px;
    color: #000000;
    margin-bottom: 32px;
    @include media-between(xs, sm) {
      font-size: 16px;
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19.6px;
    color: #000000;
    @include media-between(xs, sm) {
      font-size: 14px;
    }
  }
  .api {
    display: inline-block;
    margin-top: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-decoration: underline;
    @include media-between(xs, sm) {
      font-size: 14px;
    }
  }
}
