@import 'assets/css/variables.scss';
.home-container {
  background-color: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
  overflow: hidden;
  .section-title {
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: $text-main;
    margin-bottom: 32px;
    @include media-up(md) {
      font-size: 32px;
      line-height: 4rem;
    }
    @include media-up(lg) {
      font-size: 48px;
      line-height: 6rem;
    }
  }
}

///`12
