@import 'assets/css/variables.scss';

.wrapper-form-login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @include media-up(xs) {
    padding: 8px;
  }
  @include media-up(xm) {
    padding: 24px 16px;
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    backdrop-filter: unset;
    background: unset;
    padding-top: 8% !important;
  }

  h2 {
    color: #ffffff;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 32px;
  }
  .form-login {
    padding: 24px;
    .ant-form {
      height: 287px;
      @include media-up(sm) {
        height: 300px;
      }
    }
    @include media-up(xs) {
      width: 100%;
      max-height: 550px;
      padding: 24px 8px;
      background: #ffffff;
      border-radius: 4px;
    }
    @include media-up(xm) {
      padding: 24px 16px;
    }
    @include media-up(md) {
      max-width: 580px;
      max-height: 550px;
      padding: 60px;
      border-radius: 30px;
    }
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px 60px;
      max-width: 630px;
      max-height: 674px;
      height: 100%;
      background: #ffffff;
    }
    .form-title {
      font-size: 20px;
    }
    .wrapper-label {
      a {
        color: #003e9d;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .form-action {
      width: 100%;
      max-width: 110px;
      margin: 16px auto;

      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      @include media-up(sm) {
        width: 150px;
      }

      @include media-up(sm) {
        max-width: 287px;
        margin: 24px auto;
      }
    }

    .form-links {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin-top: 24px;
      @include media-up(sm) {
        margin-top: 48px;
      }
      a {
        color: #003e9d;
        text-decoration: underline;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-left: 5px;

        @include media-up(sm) {
          font-size: 16px;
        }

        &:hover {
          opacity: 0.7;
        }
        &:active {
          opacity: 1;
        }
      }
    }
  }
  .ant-form-item-control-input-content {
    height: 50px;

    input {
      height: 100%;
      border: 1px solid #003e9d;
      border-radius: 5px;
      background: #f2faff;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #003e9d;
    }
  }
  .ant-input-affix-wrapper.ant-input-password {
    border: 1px solid #003e9d;
    border-radius: 5px;
    background: #f2faff;
    height: 50px;
    input {
      border: none;
      background: none;
      height: auto;
    }
  }
  // .ant-form-item-has-error .ant-input,
  // .ant-form-item-has-error .ant-input-affix-wrapper,
  // .ant-form-item-has-error .ant-input:hover,
  // .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  //   border: 1px solid #ff4d4f;
  // }
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper.ant-input-password {
    border: 1px solid #ff4d4f;
  }
  .ant-form-item-has-error .ant-input:hover {
    background: #f2faff;
  }
}
