@import 'assets/css/variables.scss';

.item-history-transaction {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  //border-bottom: 1px solid #b7bbce;
  border-radius: 12px;
  border: 1px solid #08a7b7;
  background: #eefbff;
  // @include media-between(sm, lg) {
  //   width: 250px;
  // }
  .item-recent-transaction-amount {
    //color: #27b38c !important;
  }
  .item-recent-transaction-view {
    color: #003e9d;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    // border-bottom: none;
  }

  > div {
    p {
      margin-bottom: 16px;
      &:first-child {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: #292929;
      }
      &:last-child {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        text-align: right;
        color: #292929;
      }

      &[data-status='COMPLETED'] {
        width: 129px;
        height: 30px;

        background: #389201;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 140%;
          /* or 22px */

          display: flex;
          align-items: center;
          text-align: center;

          color: #ffffff;
        }
      }
      span {
        font-size: 14px;
      }
    }

    &:first-child {
      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        text-align: right;

        color: #292929;

        &:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          color: #292929;
        }
        &:last-child {
          font-weight: 700;
          font-size: 14px;
          line-height: 140%;
          color: #003e9d;
        }
      }
      a {
        font-size: 14px;
      }
    }

    &:last-child {
      a {
        display: block;

        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        text-align: right;
        text-decoration-line: underline;

        color: #003e9d;
        margin-bottom: 16px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #292929;
        &:first-child {
          font-weight: 600;
        }
        &:nth-child(3) {
          font-weight: 600;
        }
      }
    }
  }
}
