@import 'assets/css/variables';

$color-label: #fff;
$color-input: #6a6a6a;
$bg-1: transparent;

.input-coppy-wrapper {
  position: relative;
  .style-input-title {
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
  h3 {
    color: $color-label;

    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 16px;
  }
  input.ant-input {
    font-size: 17px;
    color: $color-input;
    outline: unset;
    opacity: 0.7;
    cursor: pointer;
  }
  input {
    height: 30px;
    cursor: pointer;
  }
  .ant-input-affix-wrapper {
    border-radius: 5px;
    input {
      background: transparent;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-readonly,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:valid {
    border: unset;
    outline: unset;
    box-shadow: unset;
    background: unset;
  }

  .wrapper-input {
    padding: 4px 16px;
    position: relative;
    cursor: pointer;
    span {
      color: #ffa143;
    }
  }
  .copy-success {
    position: absolute;
    left: 0;
    z-index: 20;
    top: 0;
    color: red;
    background: #5b337e;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    &:active {
      opacity: 0.9;
    }
  }
  .link {
    position: absolute;
    z-index: 22;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: block;
  }
  .icon-fly {
    img {
      width: 13.5px;
    }
  }
  .not-allowed .wrapper-input,
  .not-allowed input,
  .not-allowed img {
    cursor: not-allowed !important;
  }
}
@media only screen and (max-width: 768px) {
  canvas {
    height: auto !important;
  }
  .address-input {
    max-width: 100%;
  }
}
.icon-copy {
  width: 24.75px;
  height: 24.75px;
}
