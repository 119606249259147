@import 'assets/css/variables.scss';

.ant-modal-wrap {
  height: 100vh;
}
.ant-modal.custom-modal.modal-generate-key {
  //top: 20%;
  @include media-down(sm) {
    padding: 16px;
  }
  @include media-down(xm) {
    padding: 8px;
  }
  .ant-modal-header {
    @include media-down(sm) {
      padding: 16px 8px;
    }
  }
  .ant-modal-body {
    @include media-down(sm) {
      padding: 16px 0;
    }
  }
  .ant-modal-close {
    @include media-down(sm) {
      right: 8px;
    }
  }
}
.modal-generate-key {
  .ant-modal-body {
    .input-code-container > div .input-code-item {
      @include media-down(sm) {
        height: 50px;
      }
    }
    .input-code-container > div .input-code-item input {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: #003e9d;
      @include media-down(sm) {
        font-size: 18px;
      }
    }
    p {
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 32px;
      line-height: 43px;
      margin: 48px 0 24px 0;
      color: #003e9d;
      @include media-down(sm) {
        font-size: 16px;
        margin: 8px 0 12px 0;
      }
    }
    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #003e9d;
      @include media-down(sm) {
        font-size: 12px;
      }
    }
    .verify-title {
      padding: 0 57px;
      height: 64px;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #1d1d1d;
      @include media-down(sm) {
        font-size: 16px;
        padding: 0 32px;
      }
    }
    .verify-resend {
      margin-top: 18px;
      margin-bottom: 33px;
      display: flex;
      justify-content: space-between;
      > div {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #003e9d;
        @include media-down(sm) {
          font-size: 12px;
        }
      }
    }
    .generate-confirm {
      display: flex;
      justify-content: center;
      .button-primary {
        width: 150px;
        @include media-down(sm) {
          width: 110px;
        }
      }
    }
  }
}
.custom-modal .ant-modal-content {
  background: #ffffff;
  border: 1px solid #003e9d;
  border-radius: 12px;
  @include media-down(sm) {
    padding: 0 24px;
  }
  @include media-down(380px) {
    padding: 0 16px;
  }
  @include media-down(xm) {
    padding: 0 8px;
  }
}
.custom-modal .ant-modal-header .ant-modal-title {
  margin-top: 4px;
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  color: #003e9d;
  @include media-down(sm) {
    font-size: 16px;
  }
}
