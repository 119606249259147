@import 'assets/css/variables.scss';

.package-history {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .back-icon {
    img {
      width: 14px;
      height: 28px;
      @include media-down(sm) {
        width: 12px;
        height: 16px;
      }
    }
  }

  .back-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    color: #003e9d;
    @include media-down(sm) {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
    }
  }
  .ant-table {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .ant-table-container {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: #ffffff;
    thead.ant-table-thead {
      th.ant-table-cell {
        background: transparent;
      }
    }
  }
  .package-history-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;

    color: #000000;
    text-align: center;
    &.init {
      text-align: left !important;
    }
  }
  .trans-id-txt {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #003e9d;
    &.black-txt {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    }
    &.centered {
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
  .produect-service {
    display: flex;
    gap: 8px;
    span {
      width: 50%;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;

      color: #000000;

      &:first-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        text-align: right;

        color: #003e9d;
      }
    }
  }
  .pricing {
    text-align: center;

    justify-content: center;
    display: flex;
    flex-direction: column;
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;

      color: #000000;

      &:first-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;

        color: #003e9d;
      }
    }
  }
  .ant-badge-status-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    color: #000000;
  }
  .history-pagination {
    height: 61px;
    background: #ffffff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 16px;

    .pagination-wrapper {
      display: flex;
      justify-content: space-between;
      .pagination.have-show-records {
        display: flex;
      }
    }
  }
  .ant-table-container {
    overflow: auto !important;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background: none;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      width: 3px;
      background: #003e9d !important;
    }
    &::-webkit-scrollbar-track {
      width: 3px;
      background: rgba(190, 190, 190, 0.4) !important;
    }
    // &::-webkit-scrollbar:horizontal {
    //   height: 5px;
    //   width: 5px;
    //   //display: none;
    // }
    // &::-webkit-scrollbar-thumb:horizontal {
    //   //display: none;
    //   background: #003e9d !important;
    // }
  }
}
