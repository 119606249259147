@import 'assets/css/variables.scss';
.wPage {
  @include media-down(sm) {
    padding: 0;
  }
}
.project-content {
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 12px;
  display: flex;
  padding: 24px 32px;
  gap: 32px;
  @include media-down(md) {
    padding: 20px 28px;
    gap: 28px;
  }
  @include media-down(700px) {
    padding: 16px;
    gap: 16px;
  }
  @include media-down(410px) {
    padding: 16px 8px;
    gap: 8px;
  }

  .disabled {
    opacity: 0.3;
  }
  .projects-right {
    // background: #003e9d;
    // border-radius: 12px;

    .projects-content_img {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 93px;
        height: 93px;
        object-fit: cover;
        @include media-down(md) {
          width: 80px;
          height: 80px;
        }
        @include media-down(700px) {
          width: 61px;
          height: 61px;
        }
      }
    }
  }
  .project-left {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;

    // .project-button {
    //   .ant-btn {
    //     width: 143px;
    //     height: 48px;
    //     background: #27b38c;
    //     box-shadow: 0px 4px 10px rgba(23, 105, 201, 0.25);
    //     border-radius: 12px;
    //     padding: 8px;

    //   }
    //   span {
    //     color: #ffffff;
    //   }
    // }
    .project-detail {
      //position: relative;
      display: flex;
      justify-content: center;
      align-items: end;
      gap: 16px;
      flex-direction: column;
      @include media-down(700px) {
        gap: 0;
      }

      &_setting {
        cursor: pointer;
        width: 44px;
        height: 44px;
        background: #fec200;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        //position: absolute;
        right: 0;
        top: 0;
        a {
          display: flex;
        }
        svg {
          @include media-down(700px) {
            width: 17px;
            height: 17px;
          }
        }
        @include media-down(700px) {
          width: 24px;
          height: 24px;
          border-radius: 3px;
        }
      }
      &_info {
        @include media-down(700px) {
          width: 65px;
          text-align: right;
        }
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: #003e9d;
          @include media-down(md) {
            font-size: 14px;
          }
          @include media-down(700px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
    &_info {
      display: flex;
      flex-direction: column;
      &_title {
        span {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          color: #003e9d;
          @include media-down(md) {
            font-size: 18px;
          }
          @include media-down(700px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      &_value {
        span {
          font-weight: 700;
          font-size: 32px;
          line-height: 43px;
          color: #003e9d;
          @include media-down(md) {
            font-size: 30px;
          }
          @include media-down(700px) {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
      &_desc {
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          color: #003e9d;
          @include media-down(md) {
            font-size: 14px;
          }
          @include media-down(700px) {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
