.item-recent-transaction {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #34384c;
  div {
    p {
      color: #ffffff;
    }
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  > div {
    margin-bottom: 8px;
    p {
      margin-bottom: 0;
      &:last-child {
        margin-bottom: 0;
        max-width: 60%;
        word-break: break-all;
        text-align: right;
      }

      &[data-status='COMPLETED'] {
        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 150%;
          text-align: right;
          color: #00f4a3;
          border-radius: 3px;
          min-width: 80px;
          min-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .ts-log-out {
      color: #00f4a3;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
    }
    &:first-child {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;

        &:first-child {
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
          margin-bottom: 0;
        }
      }
      a {
        font-size: 16px;
      }
    }

    &:last-child {
      a {
        display: block;

        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #00f4a3;
        margin-bottom: 16px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;

        &:nth-child(3) {
          font-weight: 600;
        }
      }
    }
  }
}
