@import 'assets/css/variables.scss';

.warp-page-account {
  padding: 32px;
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 20px;
  @include media-down(sm) {
    padding: 16px;
    border: none;
    background: none;
    border-radius: none;
  }
  &.editor {
    .page-account-content .box-info-input .input-width-pen .box-input .ant-form-item .ant-form-item-label span,
    .page-account-content .box-info-input .input-width-pen .box-input p,
    .page-account-content .box-info-password .password-left p:first-child {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }
  }
  .page-account-content {
    // max-width: 1000px;
    .ant-form-item-control {
      background: #f2faff;
    }
    margin: auto;
    @media (min-width: 991px) {
      padding: 0 15px;
      padding: 0;
    }
    .box-avatar {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 36px;
      flex-direction: column;
      @media (min-width: 991px) {
        flex-direction: row;
      }
      .avatar-content-left {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        @media (min-width: 991px) {
          flex-direction: row;
        }
        .avatar-image {
          position: relative;
          //border: 2px solid #00f4a3;
          border-radius: 128px;
          //padding: 5px;
          height: 127px;
          width: 127px;
          @media (min-width: 991px) {
            height: 68px;
            width: 68px;
          }

          img {
            &:first-child {
              height: 100%;
              width: 100%;
              object-fit: contain;
              border-radius: 128px;
              background: linear-gradient(180deg, #4fffdf 0%, #004236 100%);
            }

            &:last-child {
              position: absolute;
              width: 15px;
              height: 18px;
              right: 0;
              cursor: pointer;
              bottom: 0;
              @media (min-width: 768px) {
                bottom: -10px;
              }

              &:active {
                opacity: 0.7;
              }
            }
          }
        }
        .wrapper-info {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 20px;
          @media (min-width: 991px) {
            margin-top: 0px;
            justify-content: space-between;
          }
          .avatar-info {
            text-align: center;
            @media (min-width: 991px) {
              text-align: left;
            }
            svg path {
              fill: #003e9d;
            }
            input {
              width: 100%;
              max-width: 220px;
              background-color: transparent;
              // border: 1px solid #34384c;
              // color: #fcfcfd;
              font-weight: 700;
              background: transparent;

              font-size: 32px;
              line-height: 43px;

              color: #003e9d;
              &:hover,
              &:focus {
                outline: none;
              }
            }
            p {
              &:first-child {
                text-align: center;
                align-items: center;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                margin-bottom: 4px;

                color: #003e9d;

                //
                @media (min-width: 991px) {
                  // display: flex;
                  // font-weight: 400;
                  // font-size: 20px;
                  // line-height: 27px;
                  font-weight: 700;
                  font-size: 32px;
                  line-height: 43px;

                  color: #003e9d;
                }
                @include media-down(md) {
                  font-size: 16px;
                  line-height: 140%;

                  border-radius: 6px;
                }
                img {
                  margin-left: 6px;
                  width: 18px;
                  height: 18px;
                  cursor: pointer;
                  @media (min-width: 991px) {
                    margin-left: 7px;
                    transform: translate(-10%, 10%);
                  }
                  &:active {
                    opacity: 0.7;
                  }
                }
              }

              &:last-child {
                // font-weight: 400;
                // font-size: 14px;
                // line-height: 16px;
                // color: #fcfcfd;
                margin-bottom: 0;

                font-weight: 500;
                font-size: 16px;
                line-height: 21px;

                color: #003e9d;
              }
            }
          }
          .btn-logout-mobile {
            display: block;
            @media (min-width: 991px) {
              display: none;
            }
          }
        }
      }

      .avatar-content-right {
        // .button-primary svg path {
        //   fill: none;
        // }
        // @include media-down(lg) {
        //   position: absolute;
        //   bottom: 80px;
        // }
        display: none;
      }

      @media (min-width: 991px) {
        .avatar-content-left {
          .avatar-image {
            height: 85px;
            width: 100px;
          }

          .avatar-info {
            p {
              &:first-child {
                font-size: 18px;
                margin-bottom: 8px;
              }

              &:last-child {
                font-size: 14px;
              }
            }
          }
        }

        .avatar-content-right {
          display: block;
          button {
            color: #0075fe;
          }
          .button-primary svg path {
            fill: none;
          }
        }
      }
    }

    .box-info-input {
      margin-bottom: 32px;

      .wrap-referrer {
        margin-bottom: 32px;
      }
      .input-width-pen {
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 27px;
          color: #ffffff;
          margin-bottom: 0;
          //
        }

        .box-input {
          position: relative;
          max-width: 100%;
          // p {
          //   font-size: 18px;
          // }
          img {
            z-index: 10;
          }
          p {
            font-size: 20px;
            margin-bottom: 16px;
            font-weight: 400;
            line-height: 27px;
            color: #003e9d;
          }
          img.moblie-icon {
            position: absolute;
          }
          img.mobile-icon.phone {
            position: absolute;
            width: 12px;
            height: 18px;
            left: 24px;
            top: 59px;
            display: none;
            @include media-down(sm) {
              display: block;
            }
          }
          img.mobile-icon.address {
            position: absolute;
            width: 12px;
            height: 13px;
            left: 24px;
            top: 60px;
            display: none;
            @include media-down(sm) {
              display: block;
            }
          }
          img.mobile-icon.birth {
            position: absolute;
            width: 16px;
            height: 16px;
            left: 22.5px;
            top: 50px;
            display: none;
            @include media-down(sm) {
              display: block;
            }
          }
          &.phone-number {
            position: relative;
            p {
              z-index: 10;
              position: absolute;
              top: 57px;
              left: 10px;
              font-weight: 700;
              font-size: 16px;
              line-height: 150%;
              color: #002a8b;
              @include media-down(sm) {
                left: 49px;
              }
            }
            p::after {
              content: '';
              position: absolute;
              width: 1px;
              height: 24px;
              top: 0;
              left: 33px;
              background: #003e9d;
            }
            input {
              padding-left: 52px;
              @include media-down(sm) {
                padding-left: 88px;
              }
            }
          }

          .form-item-input label.ant-form-item-required span:before {
            content: '';
          }
          .ant-select-dropdown {
            background-color: #ffffff;
            .ant-select-item {
              .ant-select-item-option-content {
                font-size: 16px;
                padding: 6px 40px !important;
                img {
                  width: 40px;
                }
              }
            }
            .ant-select-item-option-selected {
              background-color: #f5f5f5;
            }
          }
          .ant-form-item {
            @media (min-width: 991px) {
              margin-bottom: 32px;
            }
            .ant-form-item-label {
              span {
                //
                // font-style: normal;
                // font-weight: 400;
                // font-size: 20px;
                // line-height: 16px;
                //font-family: 'Exo';
                //font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: #003e9d;
              }
            }
          }
          .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            @include media-down(sm) {
              padding: 0 24px;
            }
          }
          input {
            border: 1px solid #34384c;
            box-sizing: border-box;
            border-radius: 10px;
            height: 50px;
            width: 100%;
            padding-left: 12px;
            padding-right: 40px;
            // font-weight: 400;
            // font-size: 14px;
            // line-height: 16px;
            color: #fcfcfd;
            background-color: transparent;

            font-weight: 500;
            font-size: 16px;
            line-height: 21px;

            color: #003e9d;
            @include media-down(sm) {
              padding: 0 48px;
            }
          }

          img {
            position: absolute;
            right: 15px;
            top: 59px;
            // top: 50%;
            // transform: translateY(-50%);
            width: 16px;
            cursor: pointer;
          }
          .ant-select-focused {
            img {
              display: none;
            }
          }
          // .ant-dropdown-placement-bottomLeft .rc-virtual-list .ant-select-item-option,
          // .ant-dropdown-placement-bottomLeft .rc-virtual-list .ant-select-item,
          // .ant-select-dropdown .rc-virtual-list .ant-select-item-option,
          // .ant-select-dropdown .rc-virtual-list .ant-select-item {
          //   padding: 6px 40px;
          // }
          // .ant-select-item.ant-select-item-option-content {
          //   padding: 6px 40px !important;
          // }
          // .ant-select-dropdown .ant-select-item.ant-select-item-option-content{
          //   padding: 6px 40px !important;

          // }

          .ant-select-selector {
            background: #f2faff;
            height: 50px;
            width: 100%;
            //background-color: transparent;
            border: 1px solid #34384c;
            color: #fcfcfd;
            // font-weight: 700;
            // font-size: 14px;
            border-radius: 10px;

            font-weight: 500;
            font-size: 18px;
            line-height: 21px;

            color: #003e9d;
            // @include media-down(sm) {
            //   font-size: 14px;
            // }
            .ant-select-selection-search:focus {
            }
            .ant-select-selection-item {
              line-height: 50px;
              //padding-left: 40px;
            }

            input {
              border: unset;
              padding: 0;
            }
          }

          .ant-picker {
            background: #f2faff;
            //background-color: transparent;
            color: #fcfcfd;
            border: 1px solid #34384c;

            .ant-picker-suffix,
            .ant-picker-clear {
              opacity: 0;
            }

            input {
              background-color: transparent;
              border: none;
            }
          }
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 42px;

        .input-width-pen {
          p {
            font-size: 20px;
            margin-bottom: 16px;
          }

          .box-input {
            p {
              font-size: 20px;
              margin-bottom: 16px;
              font-weight: 400;
              line-height: 27px;
              color: #003e9d;
              z-index: 10;
            }
            &.phone-number {
              position: relative;
              p {
                position: absolute;
                top: 56px;
                left: 10px;
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                color: #002a8b;
              }
              p::after {
                content: '';
                position: absolute;
                width: 1px;
                height: 24px;
                top: 0;
                left: 33px;
                background: #003e9d;
              }
              input {
                padding-left: 52px;
              }
            }
            input {
              padding-left: 20px;
              padding-right: 45px;
              font-size: 16px;
            }

            img {
              width: 16px;
            }

            .ant-select-selector {
              font-size: 18px;
            }
          }
        }
      }
    }

    .box-info-password {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      position: relative;
      .password-left {
        width: 100%;
        p {
          &:first-child {
            // font-weight: 400;
            // font-size: 20px;
            // line-height: 27px;
            // color: #ffffff;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            //

            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: #003e9d;
          }

          &:last-child {
            // font-weight: 400;
            // font-size: 16px;
            // line-height: 150%;
            // color: #a5adcf;

            font-weight: 500;
            font-size: 16px;
            line-height: 21px;

            color: #003e9d;
          }
        }
      }

      .password-right {
        position: absolute;
        right: 0;
        .button-secondary div span {
          background: #ffffff;
          -webkit-background-clip: text;
          font-weight: 700;
          @include media-down(sm) {
            font-size: 14px;
            line-height: 140%;
          }
        }
        button {
          width: 150px;
          height: 48px;
          background: #003e9d;
          border-radius: 8px;
          @include media-down(md) {
            width: 120px;
          }
          @include media-down(sm) {
            width: 90px;
            height: 32px;

            border-radius: 6px;
          }
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 42px;
        .box-info-password {
          align-items: center;
          .password-left {
            p {
              &:first-child {
                font-size: 18px;
                margin-bottom: 8px;

                button {
                  display: none;
                }
              }

              &:last-child {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }

          .password-right {
            position: unset;
          }
        }
      }
    }

    .box-rate-sercurity {
      margin-bottom: 45px;
      .btn-edit-mobile {
        display: none;
        @include media-down(lg) {
          display: block;
          text-align: center;
        }
      }
      .button-primary svg path {
        fill: none;
      }
      > a {
        &:last-child {
          .rate-sercurity-item {
            margin-bottom: 0;
          }
        }
      }
      .rate-sercurity-item {
        // background: rgba(62, 60, 60, 0.2);
        //background: rgba(26, 48, 53, 0.9);
        backdrop-filter: blur(300px);
        // border-radius: 12px;
        // padding: 24px 20px;
        margin-bottom: 36px;

        background: #f2faff;

        padding: 24px;
        @include media-down(xm) {
          padding: 18px;
        }
        border: 1px solid #003e9d;
        border-radius: 8px;

        .rate-sercurity-item-name {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          p {
            //
            // font-weight: 400;
            // font-size: 16px;
            // line-height: 27px;
            //color: #ffffff;
            margin-left: 12px;

            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #003e9d;
          }
          img {
            width: 22px;
            height: 22px;
          }
        }

        .rate-sercurity-item-description {
          p {
            // font-weight: 400;
            // font-size: 14px;
            // line-height: 150%;
            // color: #ffffff;

            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #003e9d;
          }
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 0;

        .rate-sercurity-item {
          //padding: 36px 50px;

          .rate-sercurity-item-name {
            margin-bottom: 24px;

            p {
              // margin-left: 15px;
              // font-size: 18px;
              // font-weight: 700;
              //
            }
          }

          .rate-sercurity-item-description {
            p {
              // font-size: 18px;
              // line-height: 24px;
            }
          }
        }
      }
    }

    .log-out {
      text-align: center;

      @media (min-width: 991px) {
        display: none;
      }
    }
  }
}
