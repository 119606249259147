@import 'assets/css/variables.scss';

.warp-star {
  position: relative;
  max-width: 1290px;
  margin: 0 auto;
  @include media-down(md) {
    margin-bottom: 100px;
  }
  @include media-down(xl) {
    padding: 16px 24px;
  }
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .start-project-desc {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    &.centered {
      text-align: center;
    }
  }
  .start-project-title {
    color: #000000;
    font-weight: 700;
    font-size: 20px;
    &.centered {
      text-align: center;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #003e9d;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d9d9d9;
    border-radius: 3px;
  }
  .table {
    @include media-down(md) {
      display: none;
    }
    // @include media-down(sm) {
    //   display: none;
    // }
    .ant-table {
      border-radius: 30px;
      .ant-table-container {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        padding: 48px 60px;
        .ant-table-header {
          padding-right: 30px;
          .ant-table-thead > tr > th {
            padding: 16px 0px 5px 0px;
            color: #000000;
            font-weight: 700;
            font-size: 20px;
            text-align: left;
            background: #ffff;
            border-bottom: 1px solid #000000;
            transition: background 0.3s ease;
          }
        }
        .ant-table-body {
          padding: 32px 21px 0px 0px;
          tbody.ant-table-tbody {
            // color: red;
            & > tr {
              text-align: center;
            }
            & > tr > td {
              padding: 16px 0px 5px 0px;
              font-weight: 700;
              font-size: 16px;
              color: #000000;
              border-bottom: 0.5px solid #000000;
              transition: background 0.3s;
            }
          }
        }
      }
    }
  }
  .warp-project_btn {
    text-align: center;
    margin-top: 32px;
    // position: relative;
    // z-index: 5;
    :nth-child(1) {
      margin-right: 36px;
    }
    justify-content: space-evenly;
    @include media-down(sm) {
      display: flex;
      justify-content: center;
      justify-content: space-evenly;
      :nth-child(1) {
        margin-right: unset;
      }
      .button-primary {
        white-space: nowrap;
      }
    }
    .button-primary {
      @include media-down(sm) {
        padding: 0 6px;
      }
      @include media-down(xm) {
        font-size: 10px;
      }
    }
  }
  &:after {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/images/bg-evm3.png');

    max-width: 1440px;
    height: 495px;
    display: block;
    @include media-down(sm) {
      display: none;
    }
  }
  //mobile
  .carousel-wrapper {
    display: none;
    background: #ffff;
    border-radius: 8px;

    @include media-down(md) {
      max-width: 380px;
      margin: 0 auto;
      .slick-dots {
        bottom: -10px;
      }
      .slick-dots li button {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #a5a5a5;
      }
      .slick-dots li.slick-active button {
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background: #717171;
      }
      display: block;
    }
  }
  .ant-table-thead > tr > th {
    background: none;
  }
}
