@import 'assets/css/variables.scss';

.warp-EVM {
  .EVM_ui {
    height: 252px;
    max-width: 1290px;
    margin: 78px auto 200px;
    display: flex;
    justify-content: space-between;
    border: 2px solid #0093e5;
    border-radius: 30px;
    background-color: #ffffff;
    padding: 0 24px;
    // display: block;
    @include media-down(md) {
      display: none;
    }
    @include media-down(xl) {
      margin: 120px 24px;
    }
    .img-left {
      width: 319px;
      height: 319px;
      margin-top: -66px;
      // position: absolute;
      // top: -63px;
      @include media-down(lg) {
        display: none;
      }
      img {
        // display: block;
        width: 307px;
        height: 319px;
      }
    }
    .img-right {
      img {
        margin-top: 30px;
        width: 346px;
        height: 220px;
      }
    }
    .group-btn {
      text-align: center;
      padding-top: 43px;
      padding-bottom: 48px;
      .button-primary {
        width: 150px;
        @include media-down(sm) {
          width: 110px;
        }
      }
    }
  }

  //mobile
  .EVM_mb {
    display: none;
    padding: 0 24px;

    @include media-down(md) {
      // height: 380px;
      margin: 120px auto;
      display: block;
    }
    @include media-down(sm) {
      // height: 380px;
      margin: 120px auto;
      display: block;
    }
    .top {
      width: 100%;
      //height: 197px;
      margin-bottom: 16px;
      // position: absolute;
      // top: -63px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom {
      border-radius: 30px;
      text-align: center;
      background-color: #ffffff;
      width: 100%;
      border: 2px solid #0093e5;
      h2 {
        font-size: 20px;
      }
    }
  }
}
