@import 'assets/css/variables.scss';

.advance-wrapper {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 32px;
  background: rgba(253, 83, 83, 0.08);
  box-shadow: 0px 1px 4px #fd5353;
  border-radius: 16px;
  @include media-down(md) {
    padding: 24px;
  }
  @include media-down(sm) {
    padding: 24px 16px;
    background: rgba(253, 83, 83, 0.08);
    border-radius: 12px;
    box-shadow: none;
  }
  @include media-down(330px) {
    padding: 16px 8px;
  }
  .advance-title {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 32px;
    span {
      font-weight: 700;
      font-size: 32px;
      line-height: 43px;
      color: #003e9d;
      @include media-down(md) {
        font-size: 28px;
      }
      @include media-down(sm) {
        font-size: 16px;
        line-height: 21px;
      }
    }
    img {
      width: 48px;
      height: 48px;
      @include media-down(sm) {
        width: 24px;
        height: 24px;
      }
    }
  }
  .advance-delete {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    &_title {
      display: flex;
      gap: 8px;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
        @include media-down(sm) {
          width: 24px;
          height: 24px;
        }
      }
      span {
        font-weight: 700;
        font-size: 32px;
        line-height: 43px;
        color: #e42c2c;
        @include media-down(md) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    &_desc {
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #e42c2c;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-down(sm) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    &_btn {
      .advance-button {
        width: 184px;
        height: 45px;
        background: #be0000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #ffffff;
      }
    }
  }
}
