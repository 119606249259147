@import 'assets/css/variables.scss';
.package-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  // .package-tab {
  //   // span {
  //   //   font-weight: 500;
  //   //   font-size: 24px;
  //   //   line-height: 32px;
  //   //   color: #003e9d;
  //   //   opacity: 0.4;
  //   // }

  //   .ant-tabs-tab {
  //     font-size: 20px;
  //   }
  //   .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //     color: #fff;
  //   }
  //   .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .package-tab {
  //     span {
  //       color: #fff;
  //     }
  //   }
  // }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 20px 0;
  }
  .package-history-link {
    position: absolute;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    top: 25px;
    @include media-down(sm) {
      top: 22px;
    }
    @include media-down(xm) {
      top: 44px;
    }
    p {
      cursor: pointer;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      text-decoration-line: underline;

      color: #003e9d;
      @include media-down(sm) {
        font-size: 14px;
      }
    }
  }
  .free-limit-address {
    width: 100%;
    text-align: center;
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-decoration-line: underline;

      color: #003e9d;
      @include media-down(sm) {
        font-size: 12px;
      }
    }
  }
  .package-payment-wrapper {
    margin-bottom: 32px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    // width: 160px;
    height: 38px;
    background: #003e9d;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    opacity: 1;
    @include media-down(700px) {
      font-size: 20px;
      width: 175px;
    }
    @include media-down(sm) {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      // width: 150px;
      width: 117px;
      height: 37px;
    }
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #003e9d;
    width: 200px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
    @include media-down(700px) {
      font-size: 20px;
      width: 175px;
    }
    @include media-down(sm) {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      width: 104px;
      height: 37px;
    }
    // @include media-down(360px) {
    //   font-size: 12px;
    //   line-height: 21px;
    //   width: 110px;
    // }
  }
  // .ant-tabs-tab:first-child:after {
  //   content: '';
  //   width: 48px;
  //   height: 2px;
  //   left: 203px;
  //   background: #003e9d;
  //   opacity: 0.4;
  //   border-radius: 12px;
  //   transform: rotate(-90deg);
  //   margin-right: 32px;
  //   position: absolute;
  //   @include media-down(700px) {
  //     left: 166.5px;
  //   }
  //   @include media-down(sm) {
  //     left: 107.5px;
  //     width: 37px;
  //   }
  // }
  .ant-tabs-ink-bar {
    background: none;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
  }
}
