@import 'assets/css/variables.scss';

.edit-wrapper {
  padding: 32px;
  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media-down(md) {
    padding: 16px;
    border-radius: 12px;
  }
  .edit-package-name {
    display: flex;
    gap: 16px;
    align-items: center;
    @include media-down(xm) {
      gap: 8px;
    }
    p:first-child {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #003e9d;
      @include media-down(md) {
        font-size: 22px;
      }
      @include media-down(sm) {
        font-size: 14px;
        line-height: 19px;
      }
    }
    p:last-child {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #003e9d;
      @include media-down(md) {
        font-size: 22px;
      }
      @include media-down(sm) {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
  .edit-package-logo {
    display: flex;
    gap: 16px;
    align-items: center;
    @include media-down(xm) {
      gap: 8px;
    }
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #003e9d;
      @include media-down(md) {
        font-size: 22px;
      }
      @include media-down(sm) {
        font-size: 14px;
        line-height: 19px;
      }
    }
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      @include media-down(md) {
        width: 50px;
        height: 50px;
      }
      @include media-down(sm) {
        width: 41px;
        height: 40px;
      }
      @include media-down(xm) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .edit-package-btn {
    .button-secondary {
      width: 150px;
      @include media-down(md) {
        width: 100px;
      }
      @include media-down(455px) {
        width: 60px;
        height: 35px;
      }
    }
  }
}

// @import 'assets/css/variables.scss';

.manage-top-wrapper {
  padding: 32px;
  gap: 10px;

  background: #ffffff;
  border: 0.5px solid rgba(1, 54, 130, 0.5);
  border-radius: 20px;
  @include media-down(md) {
    padding: 24px 16px;
  }
  @include media-down(sm) {
    border-radius: 12px;
  }
  @include media-down(330px) {
    padding: 16px 8px !important;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .manage-top_logo {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 42px;
    @include media-down(md) {
      gap: 40px;
    }
    @include media-down(sm) {
      gap: 32px;
    }
    @include media-down(xm) {
      gap: 18px;
    }

    &_title {
      span {
        font-weight: 700;
        font-size: 32px;
        line-height: 43px;
        color: #003e9d;
        @include media-down(md) {
          font-size: 28px;
        }
        @include media-down(sm) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
    &_file {
      width: 100%;
      .ant-upload-drag-container {
        img {
          width: 75px;
          height: 75px;
          object-fit: cover;
        }
      }
      .ant-upload.ant-upload-drag {
        background: transparent;
        border: none;
      }

      .ant-upload.ant-upload-drag .ant-upload-drag-container {
        display: flex;
        gap: 16px;
        //justify-content: center;
        align-items: center;
        background: transparent;
        @include media-down(xm) {
          gap: 8px;
        }
      }
      .ant-upload.ant-upload-drag p.ant-upload-text {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: rgba(1, 54, 130, 0.5);
        text-align: left;
        @include media-down(sm) {
          font-size: 14px;
          line-height: 19px;
        }
      }
      .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
        width: 91px;
        height: 91px;
        border: 1.7px dashed #003e9d;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        @include media-down(sm) {
          width: 49px;
          height: 49px;
        }
        img {
          width: 75px;
          height: 75px;
          @include media-down(sm) {
            width: 41px;
            height: 41px;
          }
        }
      }
    }
  }
  .manage-top_name {
    // .ant-form-item-control-input-content{
    //     input{
    //         background: #f2faff;
    //     width: 458px;
    //     height: 51px;
    //     border: 1.00048px solid #003e9d;
    //     border-radius: 8.00387px;
    //     }
    //   }
    p.project-name {
      font-weight: 700;
      font-size: 32px;
      line-height: 43px;

      color: #003e9d;
      margin-right: 25px;
      @include media-down(sm) {
        font-size: 16px;
        line-height: 21px;
      }

      @include media-down(xm) {
        margin-right: 10px;
      }
    }
    .ant-row {
      width: 100%;
    }
    display: flex;
    .ant-form {
      display: flex;
      align-items: center;
    }
    .ant-form {
      width: 100%;
    }
    .ant-form-inline .ant-form-item > .ant-form-item-label {
      display: flex;
      align-items: center;
    }
    .ant-input {
      background: #f2faff;
      //width: 426px;
      //   width: 100%;
      height: 48px;
      border: 1.00048px solid #003e9d;
      border-radius: 8.00387px;
      @include media-down(sm) {
        height: 40px;
      }
    }
    .ant-form-item-label > label {
      font-weight: 700;
      font-size: 32px;
      line-height: 43px;
      color: #003e9d;
      margin-right: 32px;
      @include media-down(md) {
        font-size: 28px;
      }
      @include media-down(sm) {
        font-size: 16px;
        line-height: 21px;
      }
    }
    .ant-form-item-label > label::after {
      display: none;
    }

    .manage-top-wrapper .manage-top_name .ant-form {
      width: 100%;
    }
    .ant-col.ant-form-item-label {
      @include media-down(575px) {
        flex: 0 0 20%;
      }
    }
    .ant-col.ant-form-item-control {
      @include media-down(575px) {
        flex: 0 0 80%;
      }
    }
    .ant-col.ant-form-item-control:nth-child(1) {
      @include media-down(575px) {
        flex: 0 0 100%;
      }
    }
    // @include media-up(575px) {
    //   .ant-form .ant-form-item .ant-form-item-label,
    //   .ant-form .ant-form-item .ant-form-item-control {
    //     flex: -1 0 100%;
    //   }
    // }
  }
  .manage-button {
    display: flex;
    gap: 16px;
    margin-left: 32px;
    @include media-down(md) {
      margin-left: 0;
      margin-top: 24px;
    }
    .manage-btn {
      flex: 1;
      height: 48px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      &.back {
        color: #003e9d;
        border: 1px solid #003e9d;
      }

      &.save {
        background: #003e9d;

        color: #ffffff;
      }
    }
  }
}
