@import 'assets/css/variables.scss';
.navigation-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 135px;
  width: 100%;
  z-index: 2;
  //background-image: linear-gradient(to top, rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0.746), rgba(0, 0, 0, 0));
  .navigation-mobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 65px;
    max-width: 100%;
    margin: 0 auto;
    // background: rgba(62, 60, 60, 0.9);
    //background: linear-gradient(98.53deg, #000000 -6.11%, #172b30 46.34%, #000000 101.54%);
    background: rgba(119, 149, 255, 0.5);
    border-radius: 24px 24px 0 0;
    backdrop-filter: blur(4px);

    --clr: #fff;

    ul {
      display: flex;
      margin: 0 auto;
      //width: fit-content;
      width: 100%;
      justify-content: center;

      @include media-down(700px) {
        padding: 0 24px;
      }
      @include media-down(xm) {
        padding: 0 8px;
      }
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        list-style: none;
        width: 70px;
        height: 60px;
        z-index: 1;

        @include media-down(lg) {
          width: 140px;
        }
        @include media-down(700px) {
          width: 70px;
        }

        .border-icon {
          display: flex;
          align-items: center;
          width: 40px;
          height: 40px;

          background: rgba(20, 213, 255, 0.1);
          border-radius: 50px;
          @include media-down(sm) {
            width: 35px;
            height: 35px;
          }
        }
        a {
          position: relative;
          display: flex;
          align-items: center;
          //flex-direction: column;
          //width: 100%;
          text-align: center;
          font-weight: 500;
          //transform: translateX(45%);

          svg {
            width: 24px;
            position: relative;
            display: block;
            text-align: center;
            transition: 0.5s;
            // fill: var(--clr);
            @include media-down(lg) {
              width: 30px;
              height: 30px;
            }

            @include media-down(700px) {
              width: 20px;
              height: 20px;
            }
            @include media-down(xs) {
              width: 18px;
              height: 18px;
            }
          }
        }
        svg {
          height: 24px;
          position: relative;
          display: block;
          text-align: center;
          transition: 0.5s;
          margin: 0 auto;
        }
        span {
          position: absolute;
          color: var(--clr);
          font-weight: 600;
          letter-spacing: 0.05em;
          transition: 0.5s;
          opacity: 0;
          transform: translateY(20px);
        }
      }
      li.active a svg {
        //transform: translateY(-30px);
        transform: translateX(-27px);
        @include media-down(xm) {
          transform: translateX(-24px);
        }
      }
      li.active a span {
        opacity: 1;
        //transform: translateY(12px);
        font-weight: 700;
        font-size: 10px;
        line-height: 13px;

        color: #0f1b36;
        transform: translateX(25px);

        @include media-down(lg) {
          font-size: 14px;
        }

        @include media-down(700px) {
          font-size: 10px;
          transform: translateX(10px);
        }
        @include media-down(sm) {
          transform: translateX(7px);
        }
      }
      li.logout-mobile {
        svg {
          @include media-down(xs) {
            //margin-left: 10px;
          }
        }
      }
      li.active .border-icon {
        background: none;
      }
      .indicator {
        position: absolute;
        // top: -50%;
        // width: 62px;
        // height: 62px;
        // background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
        // box-shadow: 0px 10px 16px 2px rgba(66, 69, 255, 0.133);
        // border-radius: 50%;
        // border: 6px solid #000000;
        background: #ffffff;
        border-radius: 60px;
        transition: 0.5s;

        height: 40px;
        width: 85px;
        top: 15%;

        @include media-down(lg) {
          width: 140px;
        }
        // @include media-down(xs+1px) {
        //   width: 80px;
        // }

        &::before {
          content: '';
          position: absolute;
          top: 52%;
          left: -22px;

          width: 20px;
          height: 20px;
          background: transparent;
          border-top-right-radius: 20px;
          //box-shadow: 1px -10px 0 0 #000;
        }
        &::after {
          content: '';
          position: absolute;
          top: 52%;
          right: -22px;

          width: 20px;
          height: 20px;
          background: transparent;
          border-top-left-radius: 20px;
          //box-shadow: -1px -10px 0 0 #000;
        }
      }
      li:nth-child(1).active ~ .indicator {
        transform: translateX(calc(105px * 0 + 4px));
        //transform: translateX(calc(105px * 0));

        // @include media-down(md) {
        //   transform: translateX(calc(105px * 0));
        // }
        @include media-down(lg) {
          transform: translateX(-255px);
        }
        @include media-down(700px) {
          transform: translateX(-132px);
          width: 90px;
        }
        @include media-down(380px) {
          transform: translateX(-128px);
          //width: 80px;
        }
        @include media-down(321px) {
          transform: translateX(-115px);
          width: 80px;
        }
      }
      li:nth-child(2).active ~ .indicator {
        transform: translateX(calc(105px * 1 - 31px));
        // @include media-down(xs+1px) {
        //   transform: translateX(calc(105px * 1 - 44px));
        // }

        // @include media-down(md) {
        //   transform: translateX(calc(105px * 1 - 42px));
        // }
        @include media-down(lg) {
          transform: translateX(-125px);
        }
        @include media-down(700px) {
          transform: translateX(-64px);
          width: 90px;
        }
        @include media-down(xm) {
          transform: translateX(-60px);
        }
        @include media-down(321px) {
          transform: translateX(-59px);
          width: 80px;
        }
      }
      li:nth-child(3).active ~ .indicator {
        transform: translateX(calc(105px * 1 + 39px));

        // @include media-down(md) {
        //   transform: translateX(calc(105px * 1 + 22px));
        // }
        @include media-down(lg) {
          transform: translateX(15px);
        }

        @include media-down(700px) {
          transform: translateX(0px);
          width: 90px;
        }
        @include media-down(321px) {
          transform: translateX(-2px);
          width: 80px;
        }
      }
      li:nth-child(4).active ~ .indicator {
        transform: translateX(calc(105px * 2 + 4px));

        // @include media-down(md) {
        //   transform: translateX(calc(105px * 1 + 97px));
        // }
        @include media-down(lg) {
          transform: translateX(155px);
        }

        @include media-down(700px) {
          transform: translateX(66px);
          width: 90px;
        }
        @include media-down(xm) {
          transform: translateX(72px);
        }
        @include media-down(321px) {
          transform: translateX(62px);
          width: 80px;
        }
      }
      li:nth-child(5).active ~ .indicator {
        transform: translateX(calc(140px * 2 + 4px));
      }
    }
  }
}
