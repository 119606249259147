@import 'assets/css/variables.scss';

.ant-modal.custom-modal.modal-en-disable2FA {
  width: 552px !important;
  //top: 20%;
  .ant-modal-header {
    margin-top: 54px;
    padding: 0;
  }
  .ant-modal-body {
    padding: 32px 42px 48px;
    @include media-down(sm) {
      padding: 32px 16px;
    }
    @include media-down(380px) {
      padding: 32px 8px;
    }
  }
  .modal-desc-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
    @include media-down(sm) {
      font-size: 12px;
    }

    color: #003e9d;
  }
  .modal-desc-bold {
    font-weight: 700;
    color: #003e9d;
  }
  .en-disable-confirm {
    margin-top: 34px;
    display: flex;
    justify-content: center;
    gap: 32px;
    .button-primary {
      width: 150px;
      height: 48px;
      @include media-down(sm) {
        width: 110px;
        height: 40px;
      }
      &:first-child {
        background: #ffffff;
        border: 1px solid #003e9d;
        border-radius: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #003e9d;
      }
    }
  }
}
.input-code-container > div .input-code-item {
  width: 59px;
  height: 79px;
  @include media-down(sm) {
    width: 47px !important;
    height: 56px;
  }
  @include media-down(xm) {
    width: 41px !important;
    height: 50px;
  }
}
.input-code-container > div {
  display: flex;
  justify-content: space-between;
}
.ant-modal-body .input-code-container > div .input-code-item input {
  background: #f2faff;

  border: 0.5px solid #003e9d;
  border-radius: 5px;
  width: 59px !important;
  height: 79px;
  @include media-down(sm) {
    width: 47px !important;
    height: 56px;
  }
  @include media-down(xm) {
    width: 41px !important;
    height: 50px;
  }
}
