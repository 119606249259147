@import 'assets/css/variables.scss';

.soon-container {
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    text-align: center;
    @include media-up(sm) {
      width: fit-content;
      max-width: 90%;
    }
    span {
      font-size: 34px;
      font-weight: bold;
      white-space: nowrap;
      @include media-up(sm) {
        font-size: 50px;
      }
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 20px;
    }
  }
}
